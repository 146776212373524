// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React from "react";
import Stack from "@mui/material/Stack";
import { Roger } from "@fe/roger-core";
import type { Quad } from "@rdfjs/types";
import type { SparqlConfig } from "../utils/types";
import { handleCreate } from "../sparql/handleCreate";
import { useSnackbar } from "../hooks/useSnackbar";

interface endpoints {
  queryEndpoint: string;
  updateEndpoint?: string;
  transactionEndpoint?: string;
}
interface RogerFrameProps {
  schema: string;
  config: SparqlConfig;
  initialQuads: string;
  endpoints: endpoints;
  onAnnotationCreated?: () => void;
}

export default function RogerFrame({
  schema,
  config,
  initialQuads,
  endpoints,
  onAnnotationCreated,
}: RogerFrameProps) {
  const [changed, setChanged] = React.useState<boolean>(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  async function handleSave(initialQuads: Quad[]) {
    try {
      const response = await handleCreate(initialQuads, config);
      if (response?.success) {
        if ('createdNode' in response && response.createdNode == "Annotation") {
          onAnnotationCreated?.();
          } else {
          showSnackbar("Resource has been created", "success");
          }
      }
    } catch (error) {
      console.error("Error saving annotation:", error);
      showSnackbar("An unexpected error occurred!", "error");
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Roger
        schema={schema}
        data={initialQuads}
        onSave={config ? handleSave : undefined}
        useChanged={[changed, setChanged]}
        endpoints={endpoints}
      />
      {SnackbarComponent}
    </Stack>
  );
}
