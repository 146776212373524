// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

const PREFIXES = `PREFIX sh: <http://www.w3.org/ns/shacl#>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX sorsh: <https://star.sub.uni-goettingen.de/schema/>
PREFIX sor: <https://star.sub.uni-goettingen.de/model/>
PREFIX sorvocE: <https://star.sub.uni-goettingen.de/voc/egotexttype/>
PREFIX sorvocC: <https://star.sub.uni-goettingen.de/voc/contributionrole/>
PREFIX sorvocR: <https://star.sub.uni-goettingen.de/voc/referencetype/>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX roger: <https://www.sub.uni-goettingen.de/roger/schema#>
PREFIX dash: <http://datashapes.org/dash#>
PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
PREFIX bf: <http://id.loc.gov/ontologies/bibframe-2-2-0/>
PREFIX oa: <http://www.w3.org/ns/oa#>
PREFIX foaf: <http://xmlns.com/foaf/0.1/>
PREFIX owl: <http://www.w3.org/2002/07/owl#>
PREFIX intro: <https://w3id.org/lso/intro/beta202304#>
PREFIX dct: <http://purl.org/dc/terms/>
PREFIX locvoc: <http://id.loc.gov/vocabulary/relators/#>
PREFIX sord: <https://star.sub.uni-goettingen.de/data/>
`;

//seeAlsoShape:

export const seeAlsoShape = `"""
${PREFIXES}
select ?annotation ?exact where
{ ?annotation a oa:Annotation ;
  oa:hasTarget ?target .
  ?target oa:hasSelector ?selector .
  ?selector oa:refinedBy ?refinedBy .
  ?refinedBy oa:exact ?exact . }"""@en`;

//sourceEquivalentPassageShape / literarySourceStatedShape / LiterarySourceProposedShape:

export const sourcePassageShape = `"""
${PREFIXES}
select ?sourcepassage ?wording where
{ ?sourcepassage a sor:SourcePassage ;
  intro:R44_has_wording ?wording . }"""@en`;

//researchEquivalentPassageShape / notedInShape:

export const researchPassageShape = `"""
${PREFIXES}
select ?sourcepassage ?mainTitle where
{ ?sourcepassage a sor:ResearchLiteraturePassage ;
  bf:partOf ?instance .
  ?instance bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }"""@en`;

//egotextEquivalentPassageShape / egotextShape:

export const egotextPassageShape = `"""
${PREFIXES}
select ?egotextpassage ?wording where
{ ?egotextpassage a sor:EgotextPassage ;
  intro:R44_has_wording ?wording . }"""@en`;

//sourcePassagePartOfShape / researchPassagePartOfShape / egotextPassagePartOfShape / instancePartOfShape:

export const partOfShape = `"""
${PREFIXES}
select ?instance ?mainTitle where
{ ?instance a bf:Instance ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }"""@en`;

//hasTypeShape :

export const hasTypeShape = `"""
${PREFIXES}
select ?concept ?prefLabel where
{ ?concept skos:inScheme sorvocR:ReferenceTypeConceptScheme ;
  skos:prefLabel ?prefLabel . 
FILTER (lang(?prefLabel) = 'en')}"""@en`;

//contributionRoleShape:

export const hasRoleShape = `"""
${PREFIXES}
select ?concept ?prefLabel where
{ ?concept skos:inScheme sorvocC:ContributionRoleConceptScheme ;
  skos:prefLabel ?prefLabel . 
FILTER (lang(?prefLabel) = 'en')}"""@en`;

//whichAuthorStatedShape / authorProposedShape / contributionAgentShape:

export const personShape = `"""
${PREFIXES}
select ?person ?name where
{ ?person a foaf:Person ;
  foaf:name ?name . }"""@en`;

//inSchemeShape:

export const inSchemeShape = `"""
${PREFIXES}
select ?scheme ?title where
{ ?scheme a skos:ConceptScheme ;
  dct:title ?title . }"""@en`;

//broaderShape:

export const broaderShape = `"""
${PREFIXES}
select ?concept ?label where
{ ?concept a skos:Concept ;
  skos:prefLabel ?label . }"""@en`;

//hasEgotextTypeShape :

export const hasEgotextTypeShape = `"""
${PREFIXES}
select ?concept ?prefLabel where
{ ?concept skos:inScheme sorvocE:EgotextTypeConceptScheme ;
  skos:prefLabel ?prefLabel . 
FILTER (lang(?prefLabel) = 'en')}"""@en`;
