// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import type { Quad } from "@rdfjs/types";
import N3 from "n3";
import dataFactory from "@rdfjs/data-model";
import * as RST from "rdf-string-ttl";
import axios from "axios";
import type { SparqlConfig } from "../utils/types";
import IRIs from "../utils/IRIs";

/**
 * Update a resource, that is an array of Quads with the same subject, in a
 * triple store with SPARQL.
 *
 * @param quads
 */

function flattenQuads(quads: N3.Quad[]): N3.Quad[] {
  const flattenedQuads: N3.Quad[] = [];
  const quadSet = new Set<string>();

  const processQuad = (quad: N3.Quad) => {
    const quadString = `${quad.subject.value}|${quad.predicate.value}|${quad.object.value}`;

    if (quadSet.has(quadString)) {
      return;
    }

    quadSet.add(quadString);

    if (Array.isArray(quad.object.value)) {
      const nestedQuads = quad.object.value as N3.Quad[];
      const firstNestedQuad = nestedQuads[0];
      const subject =
        quad.subject.termType === "NamedNode"
          ? dataFactory.namedNode(quad.subject.value)
          : dataFactory.blankNode(quad.subject.value);
      const linkingQuad = dataFactory.quad(
        subject,
        dataFactory.namedNode(quad.predicate.value),
        dataFactory.blankNode(firstNestedQuad.subject.value)
      );
      processQuad(linkingQuad as N3.Quad);
      nestedQuads.forEach((nestedQuad) => processQuad(nestedQuad));
    } else {
      flattenedQuads.push(quad);
    }
  };

  quads.forEach((quad) => processQuad(quad));

  return flattenedQuads;
}

/**
 * Update a resource, that is an array of Quads with the same subject, in a
 * triple store with SPARQL.
 *
 * @param quads
 */
export async function handleCreate(quads: Quad[], config: SparqlConfig) {
  if (quads.length) {
    const quadsList = flattenQuads(quads as N3.Quad[]);
    const stringifiedQuads: RST.IStringQuad[] = quadsList.map((q) =>
      RST.quadToStringQuad(q)
    );
    let query = "";
    for (const sq of stringifiedQuads)
      query += `${sq.subject}  ${sq.predicate} ${sq.object} .\n`;

    const typePredicate = IRIs.rdf.type;
    const typeQuad = quadsList.find(q => q.predicate.value === typePredicate);
    const typeObject = typeQuad ? typeQuad.object.value : null;

    if (typeObject === 'http://www.w3.org/ns/oa#Annotation') {
      try {
        const request = await axios.post(config.sparqlUpdateEndpoint, query, {
          headers: { "content-type": "text/turtle" },
        });
        if (request.status === 204) {
          const response = { success: true, message: "Annotation Created Successfully", createdNode: "Annotation" };
          return response;
        }
      } catch (error) {
        console.error("Error adding annotation:", error);
        return { success: false, message: "Failed to add annotation!" };
      }
    } else {
      try {
        const request = await axios.post(config.sparqlUpdateEndpoint, query, {
          headers: { "content-type": "text/turtle" },
        });
        if (request.status === 204) {
          const response = { success: true, message: "Resource Created Successfully", createdNode: typeObject as string };
          return response;
        }
      } catch (error) {
        console.error("Error adding annotation:", error);
        return { success: false, message: "Failed to add annotation!" };
      }
    }
  }
}