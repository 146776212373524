// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import {
  seeAlsoShape,
  hasTypeShape,
  personShape,
  sourcePassageShape,
  researchPassageShape,
  egotextPassageShape,
  partOfShape,
  hasRoleShape,
  inSchemeShape,
  broaderShape,
  hasEgotextTypeShape
} from "./AutoCompleteEditorQueries";

const Schema = `@prefix sh: <http://www.w3.org/ns/shacl#> .
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
@prefix sorsh: <https://star.sub.uni-goettingen.de/schema/> .
@prefix sor: <https://star.sub.uni-goettingen.de/model/> .
@prefix sorvocE: <https://star.sub.uni-goettingen.de/voc/egotexttype/> .
@prefix sorvocC: <https://star.sub.uni-goettingen.de/voc/contributionrole/> .
@prefix sorvocR: <https://star.sub.uni-goettingen.de/voc/referencetype/> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix roger: <https://www.sub.uni-goettingen.de/roger/schema#> .
@prefix dash: <http://datashapes.org/dash#> .
@prefix skos: <http://www.w3.org/2004/02/skos/core#> .
@prefix bf: <http://id.loc.gov/ontologies/bibframe-2-2-0/> .
@prefix oa: <http://www.w3.org/ns/oa#> .
@prefix foaf: <http://xmlns.com/foaf/0.1/> .
@prefix owl: <http://www.w3.org/2002/07/owl#> .
@prefix intro: <https://w3id.org/lso/intro/beta202304#> .
@prefix dct: <http://purl.org/dc/terms/> .
@prefix locvoc: <http://id.loc.gov/vocabulary/relators/#> .
@prefix sord: <https://star.sub.uni-goettingen.de/data/> .

sorsh:shapesGraph1 {

###Web Annotation Model

sorsh:AnnotationShape
  a sh:NodeShape ;
  sh:deactivated false ;
  roger:formNode true ;
  sh:targetClass oa:Annotation ;
  rdfs:label "Create an annotation"@en ;
  rdfs:comment "A shape specifying the class Annotation." ;
  sh:property sorsh:hasBodyShape,
              sorsh:seeAlsoShape.

sorsh:hasBodyShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class intro:INT7_Interpretament ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An annotation should have exactly one Interpretament as body."@en ;
  sh:severity sh:Violation ;
  sh:path oa:hasBody;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:InterpretamentShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Create an annotation"@en .

sorsh:seeAlsoShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class oa:Annotation ;
  sh:message "An annotation can refer to any amount of other annotations with see also."@en ;
  sh:severity sh:Violation ;
  sh:path sor:seeAlso;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:AnnotationShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  roger:sparqlStatement ${seeAlsoShape} ;
  roger:searchValue "sourcepassage";
  roger:searchLabel "exact";
  sh:name "Link to other annotations"@en .

###Intertextual Relationships

sorsh:InterpretamentShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass intro:INT7_Interpretament ;
  rdfs:label "Interpretament"@en ;
  rdfs:comment "A shape specifying the class Interpretament." ;
  sh:property sorsh:identifiesShape,
              sorsh:literarySourceProposedShape,
              sorsh:authorProposedShape,
              sorsh:notedInShape,
              sorsh:egotextShape,
              sorsh:noteShape.

sorsh:identifiesShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class intro:INT3_IntertextualRelationship ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An interpretament should identify exactly one Intertextual Relationship."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R21_identifies ;
  dash:editor dash:DetailsEditor ;
  sh:node sorsh:IntertextualRelationshipShape ;
  sh:nodeKind sh:BlankNode ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name ""@en .

sorsh:IntertextualRelationshipShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass intro:INT3_IntertextualRelationship ;
  rdfs:label "Intertextual Relationship"@en ;
  rdfs:comment "A shape specifying the class Intertextual Relationship." ;
  sh:property sorsh:hasTypeShape,
              sorsh:hasFormalAppearanceShape.

sorsh:hasTypeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An intertextual relationship should have exactly one reference type."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R19_has_type;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Please select the type of intertextual relation."@en ;
  sh:order "1"^^xsd:decimal ;
  roger:sparqlStatement ${broaderShape} ;
  roger:searchValue "concept";
  roger:searchLabel "label";
  sh:name "* Select the type of reference"@en .

sorsh:hasFormalAppearanceShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:FormalAppearance ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation should have exactly one description of formal appearance."@en ;
  sh:severity sh:Violation ;
  sh:path sor:hasFormalAppearance ;
  dash:editor dash:DetailsEditor ;
  sh:node sorsh:FormalAppearanceShape ;
  sh:nodeKind sh:BlankNode ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Explicit Reference?"@en .

sorsh:FormalAppearanceShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass sor:FormalAppearance ;
  rdfs:label "Explicit Reference?"@en ;
  rdfs:comment "A shape specifying the class Formal Appearance." ;
  sh:property sorsh:hasQuotationMarksShape,
              sorsh:authorStatedShape,
              sorsh:whichAuthorStatedShape,
              sorsh:titleStatedShape,
              sorsh:literarySourceStatedShape.

sorsh:hasQuotationMarksShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:boolean ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "It should be specified exactly once for a formal appearance whether its intertextual relation has quotation marks or not."@en ;
  sh:severity sh:Violation ;
  sh:path sor:hasQuotationMarks;
  dash:editor dash:BooleanSelectEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Does the passage have quotation marks?"@en .

sorsh:authorStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:boolean ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "It should be specified exactly once for a formal appearance whether the source of its intertextual relation has its author stated or not."@en ;
  sh:severity sh:Violation ;
  sh:path sor:authorStated;
  dash:editor dash:BooleanSelectEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "4"^^xsd:decimal ;
  sh:name "* Is the author explicitly stated?"@en .

sorsh:whichAuthorStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation can have at most one person stated as the author."@en ;
  sh:severity sh:Violation ;
  sh:path sor:author;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape;
  sh:nodeKind sh:IRI ;
  sh:description "Who is stated as the author of the reference text?"@en ;
  sh:order "5"^^xsd:decimal ;
  roger:sparqlStatement ${personShape};
  roger:searchValue "person";
  roger:searchLabel "name";
  sh:name "Insert the name of the author"@en .

sorsh:titleStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:boolean ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "It should be specified exactly once for a formal appearance whether the source of its intertextual relation has its title stated or not."@en ;
  sh:severity sh:Violation ;
  sh:path sor:titleStated;
  dash:editor dash:BooleanSelectEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "6"^^xsd:decimal ;
  sh:name "* Is the title of the literary source explicitly stated?"@en .

sorsh:literarySourceStatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:maxCount 1 ;
  sh:message "A Source Assignment by Franz Rosenzweig should have exactly one Source Passage as source."@en ;
  sh:severity sh:Violation ;
  sh:path sor:literarySource;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "7"^^xsd:decimal ;
  roger:sparqlStatement ${sourcePassageShape};
  roger:searchValue "sourcepassage";
  roger:searchLabel "wording";
  sh:name "Insert title or cited passage of the source "@en .

sorsh:literarySourceProposedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:maxCount 1 ;
  sh:message "A Source Assignment by the annotator should have exactly one Source Passage as source."@en ;
  sh:severity sh:Violation ;
  sh:path sor:literarySource;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  roger:sparqlStatement ${sourcePassageShape} ;
  roger:searchValue "sourcepassage";
  roger:searchLabel "wording";
  sh:name "Insert title or cited passage of an implicit source "@en .

sorsh:authorProposedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation can have at most one person proposed as the author"@en ;
  sh:severity sh:Violation ;
  sh:path sor:author;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "3"^^xsd:decimal ;
  roger:sparqlStatement ${personShape};
  roger:searchValue "person";
  roger:searchLabel "name";
  sh:name "Suggest an alternative to an explicitly stated author"@en .

sorsh:notedInShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:ResearchLiteraturePassage ;
  sh:message "An Interpretament can have any amount of Research Literature Passages in which its content was noted in."@en ;
  sh:severity sh:Violation ;
  sh:path sor:notedIn;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ResearchLiteraturePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Research literature which talks about this intertextual relation."@en ;
  sh:order "5"^^xsd:decimal ;
  roger:sparqlStatement ${researchPassageShape} ;
  roger:searchValue "sourcepassage";
  roger:searchLabel "mainTitle";
  sh:name "Reference research literature related to this intertextual relation"@en .

sorsh:egotextShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:EgotextPassage ;
  sh:message "An Interpretament can have any amount of Egotext Passages which are related to it."@en ;
  sh:severity sh:Violation ;
  sh:path sor:egotext;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:EgotextPassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Any writing by Franz Rosenzweig which relates to this intertextual relation."@en ;
  sh:order "6"^^xsd:decimal ;
  roger:sparqlStatement ${egotextPassageShape} ;
  roger:searchValue "egotextpassage";
  roger:searchLabel "wording";
  sh:name "Reference Franz Rosenzweig's letters, diaries, published works or archival material related to this intertextual relation"@en .

sorsh:noteShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "An intertextual relation should have at most one note."@en ;
  sh:severity sh:Violation ;
  sh:path sor:note;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Provide any support for your proposed source."@en ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Provide justification or additional information about this intertextual relation"@en .

###Passages

sorsh:SourcePassageShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass sor:SourcePassage ;
  rdfs:label "Reference a primary source"@en ;
  rdfs:comment "A shape specifying the class Source Passage." ;
  sh:property sorsh:sourcePassagePartOfShape,
              sorsh:sourceHasWordingShape,
              sorsh:contextShape,
              sorsh:sourcePartShape,
              sorsh:sourceOriginalLinkShape,
              sorsh:sourceModifiedLinkShape,
              sorsh:sourceEquivalentPassageShape.

sorsh:ResearchLiteraturePassageShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass sor:ResearchLiteraturePassage ;
  rdfs:label "Reference secondary research literature"@en ;
  rdfs:comment "A shape specifying the class Research Literature Passage." ;
  sh:property sorsh:researchPassagePartOfShape,
              sorsh:researchOriginalLinkShape,
              sorsh:researchModifiedLinkShape,
              sorsh:partShape,
              sorsh:researchEquivalentPassageShape.
              
sorsh:EgotextPassageShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass sor:EgotextPassage ;
  rdfs:label "Reference egotexts"@en ;
  rdfs:comment "A shape specifying the class Egotext Passage." ;
  sh:property sorsh:egotextPassagePartOfShape,
              sorsh:egotextHasWordingShape,
              sorsh:egotextOriginalLinkShape,
              sorsh:egotextModifiedLinkShape,
              sorsh:partShape,
              sorsh:egotextEquivalentPassageShape.

sorsh:sourcePassagePartOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Instance ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should be part of exactly one Instance."@en ;
  sh:severity sh:Violation ;
  sh:path bf:partOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourceInstanceShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  roger:sparqlStatement ${partOfShape};
  roger:searchValue "instance";
  roger:searchLabel "mainTitle";
  sh:name "* Edition you used"@en .

sorsh:researchPassagePartOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Instance ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should be part of exactly one Instance."@en ;
  sh:severity sh:Violation ;
  sh:path bf:partOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ResearchInstanceShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  roger:sparqlStatement ${partOfShape};
  roger:searchValue "instance";
  roger:searchLabel "mainTitle";
  sh:name "* Edition you used"@en .

sorsh:egotextPassagePartOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Instance ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should be part of exactly one Instance."@en ;
  sh:severity sh:Violation ;
  sh:path bf:partOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:EgotextInstanceShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  roger:sparqlStatement ${partOfShape};
  roger:searchValue "instance";
  roger:searchLabel "mainTitle";
  sh:name "* Edition you used"@en .

sorsh:sourceHasWordingShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Literary Source or Egotext Passage should have exactly one wording."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R44_has_wording;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  sh:name "* Insert quotation as written in the source"@en .

sorsh:egotextHasWordingShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A Literary Source or Egotext Passage should have exactly one wording."@en ;
  sh:severity sh:Violation ;
  sh:path intro:R44_has_wording;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Please provide here the text constituting the referenced passage as found in the source."@en ;
  sh:order "2"^^xsd:decimal ;
  sh:name "* Insert relevant passage in Rosenzweig's egotext"@en .

sorsh:contextShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Source Passage should have exactly one context."@en ;
  sh:severity sh:Violation ;
  sh:path sor:context;
  dash:editor dash:TextAreaEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Insert the full relevant sentence or paragraph in the cited source."@en ;
  sh:order "3"^^xsd:decimal ;
  sh:name "* Insert larger context of the quotation in the source"@en .

sorsh:sourcePartShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should correspond to exactly one part."@en ;
  sh:severity sh:Violation ;
  sh:path bf:part;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "4"^^xsd:decimal ;
  sh:name "* Insert page number or verse number of the passage"@en .

sorsh:partShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A Passage should correspond to exactly one part."@en ;
  sh:severity sh:Violation ;
  sh:path bf:part;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "4"^^xsd:decimal ;
  sh:name "* Insert relevant page numbers"@en .

sorsh:sourceOriginalLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A Passage should have at most one preferred link."@en ;
  sh:severity sh:Violation ;
  sh:path sor:originalLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description "Insert the URL of a website hosting the source text. This link should lead to the specific passage (rather than to the fulltext) and be in an edition close to what Franz Rosenzweig likely used."@en ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Link to website displaying the passage"@en .
  
sorsh:researchOriginalLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A Passage should have at most one preferred link."@en ;
  sh:severity sh:Violation ;
  sh:path sor:originalLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Link to website displaying the section of the research literature"@en .
  
sorsh:egotextOriginalLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A Passage should have at most one preferred link."@en ;
  sh:severity sh:Violation ;
  sh:path sor:originalLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Link to website displaying the passage"@en .

sorsh:sourceModifiedLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A Passage can have zero or more direct links."@en ;
  sh:severity sh:Violation ;
  sh:path sor:modifiedLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description "Insert the URL of a website hosting the source text. This link should lead to the specific passage (rather than to the fulltext) and be in an edition differing from what Franz Rosenzweig likely used (e.g. a translation or re-edition)."@en ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Link to website displaying the passage in different version"@en .
  
sorsh:researchModifiedLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A Passage can have zero or more direct links."@en ;
  sh:severity sh:Violation ;
  sh:path sor:modifiedLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Link to website displaying the section of the research literature in translation."@en .
  
sorsh:egotextModifiedLinkShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A Passage can have zero or more direct links."@en ;
  sh:severity sh:Violation ;
  sh:path sor:modifiedLink ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Link to website displaying the passage in translation"@en .

sorsh:sourceEquivalentPassageShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:SourcePassage ;
  sh:message "A Passage can have any amount of equivalent passages."@en ;
  sh:severity sh:Violation ;
  sh:path sor:equivalentPassage;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:SourcePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Link this passage to other passages in the database which refer to the same text."@en ;
  sh:order "7"^^xsd:decimal ;
  roger:sparqlStatement ${sourcePassageShape} ;
  roger:searchValue "sourcepassage";
  roger:searchLabel "wording";
  sh:name "Link to other passages from literary works"@en .

sorsh:researchEquivalentPassageShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:ResearchLiteraturePassage ;
  sh:message "A Passage can have any amount of equivalent passages."@en ;
  sh:severity sh:Violation ;
  sh:path sor:equivalentPassage;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ResearchLiteraturePassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Link this passage to other passages in the database which refer to the same text."@en ;
  sh:order "7"^^xsd:decimal ;
  roger:sparqlStatement ${researchPassageShape} ;
  roger:searchValue "sourcepassage";
  roger:searchLabel "mainTitle";
  sh:name "Link to other passages from research literature"@en .

sorsh:egotextEquivalentPassageShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class sor:EgotextPassage ;
  sh:message "A Passage can have any amount of equivalent passages."@en ;
  sh:severity sh:Violation ;
  sh:path sor:equivalentPassage;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:EgotextPassageShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Link this passage to other passages in the database which refer to the same text."@en ;
  sh:order "7"^^xsd:decimal ;
  roger:sparqlStatement ${egotextPassageShape} ;
  roger:searchValue "sourcepassage";
  roger:searchLabel "wording";
  sh:name "Link to other passages from Rosenzweig's egotexts"@en .

###Bibliographic Framework

#InstanceShape has a version for validation (the other version is deactivated for that) because from there, there is a different indirect path each to bf:role, so that it can be validated that they each have only those roles that are specific to instance  contributions and work contributions.

sorsh:SourceInstanceShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass bf:Instance ;
  rdfs:label "Add a new book, letter or article"@en ;
  rdfs:comment "The edition/version which a passage is found in." ;
  sh:property sorsh:titleShape,
              sorsh:contributionShape,
              sorsh:instancePartOfShape,
              sorsh:pagesShape,
              sorsh:electronicLocatorShape,
              sorsh:provisionActivityShape,
              sorsh:hasWikidataShape,
              sorsh:hasLOCShape,
              sorsh:hasGNDShape.

sorsh:ResearchInstanceShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass bf:Instance ;
  rdfs:label "Add a new book, letter or article"@en ;
  rdfs:comment "The edition/version which a passage is found in." ;
  sh:property sorsh:titleShape,
              sorsh:contributionShape,
              sorsh:instancePartOfShape,
              sorsh:pagesShape,
              sorsh:electronicLocatorShape,
              sorsh:provisionActivityShape.

sorsh:EgotextInstanceShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass bf:Instance ;
  rdfs:label "Add a new book, letter or article"@en ;
  rdfs:comment "The edition/version which a passage is found in." ;
  sh:property sorsh:titleShape,
              sorsh:contributionShape,
              sorsh:instancePartOfShape,
              sorsh:pagesShape,
              sorsh:electronicLocatorShape,
              sorsh:provisionActivityShape,
              sorsh:hasWikidataShape,
              sorsh:hasLOCShape,
              sorsh:hasGNDShape,
              sorsh:hasEgotextTypeShape.

sorsh:titleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Title ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one title."@en ;
  sh:severity sh:Violation ;
  sh:path bf:title;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:InstanceTitleShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Title"@en .

sorsh:TitleShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Title ;
  rdfs:label "Title"@en ;
  rdfs:comment "A shape specifying the class Title." ;
  sh:property sorsh:mainTitleShape,
              sorsh:subtitleShape.

sorsh:mainTitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "An instance should have exactly one main title."@en ;
  sh:severity sh:Violation ;
  sh:path bf:mainTitle;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Insert (edition-specific) main title"@en .

sorsh:subtitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one subtitle."@en ;
  sh:severity sh:Violation ;
  sh:path bf:subtitle;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Insert (edition-specific) subtitle"@en .

sorsh:contributionShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Contribution ;
  sh:message "An instance can have any amount of contributions."@en ;
  sh:severity sh:Violation ;
  sh:path bf:contribution;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:InstanceContributionShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Instance Contribution"@en .

sorsh:ContributionShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Contribution ;
  rdfs:label "Contribution"@en ;
  rdfs:comment "A shape specifying the class Contribution." ;
  sh:property sorsh:contributionRoleShape,
              sorsh:contributionAgentShape.

sorsh:contributionRoleShape
  a sh:PropertyShape ;
  sh:deactivated true ;
  sh:class skos:Concept ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A contribution should have exactly one contribution role."@en ;
  sh:severity sh:Violation ;
  sh:path bf:role;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "1"^^xsd:decimal ;
  roger:sparqlStatement ${hasRoleShape};
  roger:searchValue "concept";
  roger:searchLabel "prefLabel";
  sh:name "Edition Contribution Role"@en .

sorsh:contributionAgentShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class foaf:Person ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A contribution should have exactly one person as agent."@en ;
  sh:severity sh:Violation ;
  sh:path bf:agent ;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:PersonShape ;
  sh:nodeKind sh:IRI;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  roger:sparqlStatement ${personShape};
  roger:searchValue "person";
  roger:searchLabel "name";
  sh:name "Edition Contributor"@en .

sorsh:instancePartOfShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Instance ;
  sh:maxCount 1 ;
  sh:message "An Instance should be part of at most one Instance."@en ;
  sh:severity sh:Violation ;
  sh:path bf:partOf;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:InstanceShape ;
  sh:nodeKind sh:IRI ;
  sh:description "If this is an article or book chapter which is part of another work, link it here to the that work."@en ;
  sh:order "3"^^xsd:decimal ;
  roger:sparqlStatement ${partOfShape};
  roger:searchValue "instance";
  roger:searchLabel "mainTitle";
  sh:name "Name of journal, collection or collected works"@en .

sorsh:pagesShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one specification of its page range."@en ;
  sh:severity sh:Violation ;
  sh:path sor:pages;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "If this is an article or book chapter which is part of another work, specify here on which pages it is found."@en ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Insert page numbers"@en .

sorsh:electronicLocatorShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "An instance can have any amount of electronic locators."@en ;
  sh:severity sh:Violation ;
  sh:path bf:electronicLocator ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:pattern "^http(s)?:\\/\\/.*?$";
  sh:description ""@en ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Insert the URL of a website hosting the full text of the edition"@en .

sorsh:provisionActivityShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class bf:Publication ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one provision activity."@en ;
  sh:severity sh:Violation ;
  sh:path bf:provisionActivity;
  dash:editor dash:DetailsEditor;
  sh:node sorsh:PublicationShape ;
  sh:nodeKind sh:BlankNode;
  sh:description ""@en ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Publisher and Date of Publication"@en .

sorsh:PublicationShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode false ;
  sh:targetClass bf:Publication ;
  rdfs:label ""@en ;
  rdfs:comment "A shape specifying the class Publication." ;
  sh:property sorsh:placeShape,
              sorsh:dateShape.

sorsh:placeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A provision activity should have at most one place."@en ;
  sh:severity sh:Violation ;
  sh:path bf:place ;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Place of publication"@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Insert place of publication"@en .
  
sorsh:dateShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A provision activity should have at most one date."@en ;
  sh:severity sh:Violation ;
  sh:path bf:date;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Insert date of publication"@en .

###SKOS Concepts

sorsh:SchemeShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass skos:ConceptScheme ;
  rdfs:label "Concept Scheme"@en ;
  rdfs:comment "A shape specifying the class ConceptScheme." ;
  sh:property sorsh:IRIShape,
              sorsh:schemeTitleShape,
              sorsh:schemeCreatorShape,
              sorsh:schemeCreatedShape,
              sorsh:licenseShape.

sorsh:IRIShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:maxCount 1 ;
  sh:message "A concept or scheme should have at most one IRI."@en ;
  sh:severity sh:Violation ;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "1"^^xsd:decimal ;
  sh:name "Specify the IRI (including namespace) of the concept or scheme"@en .

sorsh:schemeTitleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one title."@en ;
  sh:severity sh:Violation ;
  sh:path dct:title;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "2"^^xsd:decimal ;
  sh:name "Specify the title of the scheme"@en .

sorsh:schemeCreatorShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:path dct:creator;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "3"^^xsd:decimal ;
  sh:name "Specify the creator of the scheme"@en .

sorsh:schemeCreatedShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one creation date."@en ;
  sh:severity sh:Violation ;
  sh:path dct:created;
  dash:editor dash:DatePickerEditor ;
  sh:nodeKind xsd:date ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Specify the creation date of the scheme in the format YYYY-MM-DD"@en .

sorsh:licenseShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one license."@en ;
  sh:severity sh:Violation ;
  sh:path dct:license;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Specify the license of the scheme"@en .

sorsh:ConceptShape
  a sh:NodeShape ;
  sh:deactivated false ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass skos:Concept ;
  rdfs:label "Concept"@en ;
  rdfs:comment "A shape specifying the class Concept." ;
  sh:property sorsh:IRIShape,
              sorsh:inSchemeShape,
              sorsh:broaderShape,
              sorsh:prefLabelEnShape,
              sorsh:prefLabelDeShape,
              sorsh:definitionShape,
              sorsh:exampleShape,
              sorsh:scopeNoteShape,
              sorsh:editorialNoteShape,
              sorsh:exactMatchShape.

sorsh:inSchemeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class skos:Concept ;
  sh:maxCount 1 ;
  sh:message "A concept should be in one scheme at most."@en ;
  sh:severity sh:Violation ;
  sh:path skos:inScheme;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:AnnotationShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "2"^^xsd:decimal ;
  roger:sparqlStatement ${inSchemeShape} ;
  roger:searchValue "scheme";
  roger:searchLabel "title";
  sh:name "Select the scheme"@en .

sorsh:broaderShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class skos:Concept ;
  sh:maxCount 1 ;
  sh:message "A concept should be narrower than one other concept at most."@en ;
  sh:severity sh:Violation ;
  sh:path skos:broader;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape ;
  sh:nodeKind sh:IRI ;
  sh:description ""@en ;
  sh:order "3"^^xsd:decimal ;
  roger:sparqlStatement ${broaderShape} ;
  roger:searchValue "concept";
  roger:searchLabel "prefLabel";
  sh:name "Select which other concept is broader than this one"@en .

sorsh:prefLabelEnShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one English label."@en ;
  sh:severity sh:Violation ;
  sh:path skos:prefLabel;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "4"^^xsd:decimal ;
  sh:name "Specify the English label of the concept"@en .

sorsh:prefLabelDeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one German label."@en ;
  sh:severity sh:Violation ;
  sh:path skos:prefLabel;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "5"^^xsd:decimal ;
  sh:name "Specify the German label of the concept"@en .

sorsh:definitionShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one definition."@en ;
  sh:severity sh:Violation ;
  sh:path skos:definition;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "6"^^xsd:decimal ;
  sh:name "Specify the definition of the concept"@en .

sorsh:exampleShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one example."@en ;
  sh:severity sh:Violation ;
  sh:path skos:example;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Specify the example of the concept"@en .

sorsh:scopeNoteShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one scope note."@en ;
  sh:severity sh:Violation ;
  sh:path skos:scopeNote;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "8"^^xsd:decimal ;
  sh:name "Specify the scope note of the concept"@en .

sorsh:editorialNoteShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:maxCount 1 ;
  sh:message "A scheme should have at most one editorial note."@en ;
  sh:severity sh:Violation ;
  sh:path skos:editorialNote;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "9"^^xsd:decimal ;
  sh:name "Specify the editorial note of the concept"@en .

sorsh:exactMatchShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:path skos:exactMatch;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:order "10"^^xsd:decimal ;
  sh:name "Specify the exact match of the concept"@en .

###FOAF/Reference Databases
  
sorsh:PersonShape
  a sh:NodeShape ;
  sh:deactivated true ;
  sh:closed true ;
  sh:ignoredProperties (rdf:type) ;
  roger:formNode true ;
  sh:targetClass foaf:Person ;
  rdfs:label "Person"@en ;
  rdfs:comment "A shape specifying the class Person." ;
  sh:property sorsh:personNameShape,
              sorsh:hasWikidataShape,
              sorsh:hasGNDShape,
              sorsh:hasLOCShape.

sorsh:personNameShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:string ;
  sh:minCount 1 ;
  sh:maxCount 1 ;
  sh:message "A person should have exactly one name."@en ;
  sh:severity sh:Violation ;
  sh:path foaf:name;
  dash:editor dash:TextFieldEditor ;
  sh:nodeKind sh:Literal ;
  sh:description "Please enter the name of the person in the format first name last name."@en ;
  sh:order "1"^^xsd:decimal ;
  sh:name "* Insert full name"@en .

sorsh:hasWikidataShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A person or work may have any amount of Wikidata entries."@en ;
  sh:severity sh:Violation ;
  sh:path sor:WikidataMatch;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "7"^^xsd:decimal ;
  sh:name "Wikidata entry"@en .

sorsh:hasGNDShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A person or work may have any amount of GND entries."@en ;
  sh:severity sh:Violation ;
  sh:path sor:GNDMatch;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "8"^^xsd:decimal ;
  skos:editorialNote "The searchQueryURL needs to be added for this and LOC." ;
  sh:name "Gemeinsame Normdatei (GND)"@en .

sorsh:hasLOCShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:datatype xsd:anyURI ;
  sh:message "A person or work may have any amount of LOC entries."@en ;
  sh:severity sh:Violation ;
  sh:path sor:LOCMatch;
  dash:editor dash:URIEditor ;
  sh:nodeKind sh:Literal ;
  sh:description ""@en ;
  sh:order "9"^^xsd:decimal ;
  sh:name "Libary of Congress entry"@en .

sorsh:hasEgotextTypeShape
  a sh:PropertyShape ;
  sh:deactivated false ;
  sh:class skos:Concept ;
  sh:maxCount 1 ;
  sh:message "An instance should have at most one reference type."@en ;
  sh:severity sh:Violation ;
  sh:path sor:hasEgotextType;
  dash:editor dash:AutoCompleteEditor ;
  sh:node sorsh:ConceptShape ;
  sh:nodeKind sh:IRI ;
  sh:description "Please select the type of egotext."@en ;
  sh:order "10"^^xsd:decimal ;
  roger:sparqlStatement ${hasEgotextTypeShape} ;
  roger:searchValue "concept";
  roger:searchLabel "prefLabel";
  sh:name "Select the type of egotext"@en .

 sor:dataGraph sh:shapesGraph sorsh:shapesGraph1.
}`;

export default Schema;
