// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import Prefixes from "./Prefixes";

const AnnotationsCountQuery = `
          SELECT (COUNT(DISTINCT ?annotation) AS ?annotationCount)
          WHERE {
            ?annotation a oa:Annotation
          }
        `;

const PropertiesCountQuery = `
          SELECT (COUNT(DISTINCT ?predicate) AS ?predicateCount)
          WHERE {
            ?annotation a oa:Annotation ;
                        ?predicate ?object .
          }
        `;

const ObjectsCountQuery = `
          SELECT (COUNT(DISTINCT ?object) AS ?objectCount)
          WHERE {
            ?annotation a oa:Annotation ;
                        ?predicate ?object .
          }
        `;

const AllAnnotations = `
        SELECT ?annotation ?target ?exact ?created
                WHERE {
                ?annotation a oa:Annotation ;
                        dct:created ?created .

                OPTIONAL {
                ?annotation oa:hasTarget ?target .
                ?target a oa:SpecificResource ;
                        oa:hasSelector ?rangeSelector .

                ?rangeSelector a oa:RangeSelector ;
                                oa:refinedBy ?textQuoteSelector .

                ?textQuoteSelector a oa:TextQuoteSelector ;
                                oa:exact ?exact .
                }
        }
        ORDER BY DESC(?created)`;

const GetSingleAnnotation = (uri) => {
        const decodedUri = decodeURIComponent(uri);
        return `DESCRIBE <${decodedUri}>`;
}

const AllPersons = `select ?person ?name
                {
                ?person a foaf:Person ;
                        foaf:name ?name
                }`;

const GetSinglePerson = (uri) => {
        const decodedUri = decodeURIComponent(uri);
        return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`
}

const AllSourcePassages = `
select ?passage ?wording ?part ?mainTitle where
{ ?passage a sor:SourcePassage ;
  intro:R44_has_wording ?wording ;
  bf:partOf ?instance . 
  OPTIONAL {
    ?passage bf:partOf ?instance .
    ?instance a bf:Instance ;
            bf:title ?title .

    ?title a bf:Title ;
            bf:mainTitle ?mainTitle .
    }
  }`;

const GetSingleSourcePassage = (uri) => {
        const decodedUri = decodeURIComponent(uri);
        return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`
}

const AllResearchLiteraturePassages = `
select ?passage ?part ?mainTitle where
{ ?passage a sor:ResearchLiteraturePassage ;
  bf:part ?part . 
  OPTIONAL {
    ?passage bf:partOf ?instance .
    ?instance a bf:Instance ;
            bf:title ?title .

    ?title a bf:Title ;
            bf:mainTitle ?mainTitle .
    }
  }`;

const GetSingleResearchLiteraturePassage = (uri: string) => {
        const decodedUri = decodeURIComponent(uri);
        return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`
}

const AllInstances = `select ?instance ?mainTitle where
{ ?instance a bf:Instance ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }`;

const GetSingleInstance = (uri) => {
        const decodedUri = decodeURIComponent(uri);
        return `DESCRIBE <${decodedUri}>`;
}

const AllEgotextPassages = `
select ?passage ?part ?mainTitle where
{ ?passage a sor:EgotextPassage ;
  bf:part ?part . 
  OPTIONAL {
    ?passage bf:partOf ?instance .
    ?instance a bf:Instance ;
            bf:title ?title .

    ?title a bf:Title ;
            bf:mainTitle ?mainTitle .
    }
  }`;

const GetSingleEgotextPassage = (uri) => {
        const decodedUri = decodeURIComponent(uri);
        return `DESCRIBE
      <${decodedUri}>`;
}

const AllWorks = `select ?work ?mainTitle where
{ ?work a bf:Work ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }
  `;

const GetSingleWork = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return `DESCRIBE <${decodedUri}>`;
};

const AllPlaces = `select ?place ?name where
{ ?place a bf:Place ;
  foaf:name ?name . }
  `;

const GetSinglePlace = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllPublishers = `select ?publisher ?name where
{ ?publisher a sor:Publisher ;
  foaf:name ?name . }
  `;

const GetSinglePublisher = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllInstitutions = `select ?institution ?name where
{ ?institution a sor:GrantingInstitution ;
  foaf:name ?name . }
  `;

const GetSingleInstitution = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllIsbns = `select ?isbn ?name where
{ ?isbn a bf:Isbn ;
  foaf:name ?name . }
  `;

const GetSingleIsbn = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const DeleteQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
        DELETE WHERE {
            <${decodedUri}> ?p ?o .
        }
    `;
};

const DeleteAnnotationQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
    DELETE { 
      <${decodedUri}> ?annotationP ?annotationO.

      ?annotationO ?blankP ?blankO.

      ?blankO ?blankP2 ?blankO2.

      ?blankO2 ?blankP3 ?blankO3.
      }
    WHERE {
      <${decodedUri}> a oa:Annotation.

      OPTIONAL {
        <${decodedUri}> ?annotationP ?annotationO.
      }

      OPTIONAL {
        ?annotationO ?blankP ?blankO.
        FILTER (isBlank(?annotationO))

        OPTIONAL {
          ?blankO ?blankP2 ?blankO2.
          FILTER (isBlank(?blankO))

          OPTIONAL {
            ?blankO2 ?blankP3 ?blankO3.
            FILTER (isBlank(?blankO2))
          }
        }
      }
    }
    `;
};

const DeleteWorkQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
  ${Prefixes}
DELETE { 
    <${decodedUri}> a bf:Work;
                   sor:WikidataMatch ?WikidataMatch;
                   sor:GNDMatch ?GNDMatch;
                   sor:LOCMatch ?LOCMatch;
                   bf:originDate ?originDate;
                   bf:title ?workTitle;
                   bf:contribution ?workContribution.

    ?workContribution a bf:Contribution;
                   bf:role ?role;
                   bf:agent ?contrAgent.

    ?workTitle a bf:Title;
                   bf:mainTitle ?mainTitle;
                   bf:subtitle ?subtitle.
} 
WHERE {
    <${decodedUri}> a bf:Work.

    OPTIONAL {
        <${decodedUri}> 
            sor:WikidataMatch ?WikidataMatch;
            sor:GNDMatch ?GNDMatch;
            sor:LOCMatch ?LOCMatch.
    }

    OPTIONAL {
        <${decodedUri}> bf:originDate ?originDate;
                       bf:title ?workTitle;
                       bf:contribution ?workContribution.
    }

    OPTIONAL {
        ?workContribution a bf:Contribution;
            bf:role ?role;
            bf:agent ?contrAgent.
    }

    OPTIONAL {
        ?workTitle a bf:Title;
            bf:mainTitle ?mainTitle;
            bf:subtitle ?subtitle.
    }
}

    `;
};

const GetSingleNode = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return `DESCRIBE <${decodedUri}>`;
};

const AllConcepts = `select ?concept ?label where
{ ?concept a skos:Concept ;
  skos:prefLabel ?label . }`;

const GetSingleConcept = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return `DESCRIBE <${decodedUri}>`;
};

const AllConceptSchemes = `select ?scheme ?title where
{ ?scheme a skos:ConceptScheme ;
  dct:title ?title . }`;

const GetSingleConceptScheme = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return `DESCRIBE <${decodedUri}>`;
};

const DeleteEditionQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
    DELETE { 
      <${decodedUri}> ?instanceP ?instanceO.

      ?instanceO ?blankP ?blankO.
    } 
    WHERE {
      <${decodedUri}> a bf:Instance.

      OPTIONAL {
          <${decodedUri}> ?instanceP ?instanceO.
      }

      OPTIONAL {
          ?instanceO ?blankP ?blankO.
          FILTER (isBlank(?instanceO))
      }
    }
`;
}


const getAnnotationPreviewQuery = (annotationUri: string) => `
select distinct ?annotation ?exact ?created ?creator ?referenceTypePrefLabel ?hasQuotationMarks ?authorStated ?explicitAuthorName ?titleStated ?explicitSourceWording ?explicitLiterarySourceAuthorName ?explicitLiterarySourceMainTitle ?implicitSourceWording ?implicitLiterarySourceAuthorName ?implicitLiterarySourceMainTitle ?implicitAuthorName ?researchLiteraturePassagePart ?researchLiteratureAuthorName ?researchLiteratureMainTitle ?egotextWording ?egotextMainTitle ?seeAlso
{
  ?annotation a oa:Annotation;
    oa:hasTarget ?specificResource;
    dct:created ?created;
    oa:hasBody ?body.
    
  ?specificResource a oa:SpecificResource;
    oa:hasSelector ?rangeSelector.
    
  ?rangeSelector a oa:RangeSelector;
    oa:refinedBy ?quoteSelector.
    
  ?quoteSelector a oa:TextQuoteSelector;
    oa:exact ?exact.
    
  ?body intro:R21_identifies ?intertextualRelationship.
  
  OPTIONAL { ?annotation dct:creator ?creator. }
  OPTIONAL { ?annotation sor:seeAlso ?seeAlso. }
  
  OPTIONAL { 
    ?body sor:literarySource ?implicitLiterarySourcePassage.
    ?implicitLiterarySourcePassage intro:R44_has_wording ?implicitSourceWording.
    OPTIONAL { 
      ?implicitLiterarySourcePassage bf:partOf ?implicitLiterarySourceInstance.
      ?implicitLiterarySourceInstance bf:title ?implicitLiterarySourceTitle.
      ?implicitLiterarySourceTitle bf:mainTitle ?implicitLiterarySourceMainTitle.
      OPTIONAL { 
        ?implicitLiterarySourcePassage bf:contribution ?implicitLiterarySourceContribution.
        ?implicitLiterarySourceContribution bf:role sorvocC:Author.
        ?implicitLiterarySourceContribution bf:agent ?implicitLiterarySourceAuthor.
        ?implicitLiterarySourceAuthor foaf:name ?implicitLiterarySourceAuthorName.
      }
    }
  }
  
  OPTIONAL { 
    ?body sor:notedIn ?researchLiteraturePassage.
    ?researchLiteraturePassage bf:part ?researchLiteraturePassagePart.
    OPTIONAL { 
      ?researchLiteraturePassage bf:partOf ?researchLiteratureInstance.
      ?researchLiteratureInstance bf:title ?researchLiteratureTitle.
      ?researchLiteratureTitle bf:mainTitle ?researchLiteratureMainTitle.
      OPTIONAL { 
        ?researchLiteraturePassage bf:contribution ?researchLiteratureContribution.
        ?researchLiteratureContribution bf:role sorvocC:Author.
        ?researchLiteratureContribution bf:agent ?researchLiteratureAuthor.
        ?researchLiteratureAuthor foaf:name ?researchLiteratureAuthorName.
      }
    }
  }
  
  OPTIONAL { 
    ?body sor:egotext ?egotextPassage.
    ?egotextPassage intro:R44_has_wording ?egotextWording.
    OPTIONAL { 
      ?egotextPassage bf:partOf ?egotextInstance.
      ?egotextInstance bf:title ?egotextTitle.
      ?egotextTitle bf:mainTitle ?egotextMainTitle.
    }
  }
  
  OPTIONAL { ?body sor:note ?note. }
  OPTIONAL { 
    ?body sor:author ?implicitAuthor.
    ?implicitAuthor foaf:name ?implicitAuthorName.
  }
  OPTIONAL { 
    ?intertextualRelationship sor:hasFormalAppearance ?formalAppearance.
    OPTIONAL { 
      ?formalAppearance sor:literarySource ?explicitLiterarySourcePassage.
      ?explicitLiterarySourcePassage intro:R44_has_wording ?explicitSourceWording.
      OPTIONAL { 
        ?explicitLiterarySourcePassage bf:partOf ?explicitLiterarySourceInstance.
        ?explicitLiterarySourceInstance bf:title ?explicitLiterarySourceTitle.
        ?explicitLiterarySourceTitle bf:mainTitle ?explicitLiterarySourceMainTitle.
        OPTIONAL { 
          ?explicitLiterarySourcePassage bf:contribution ?explicitLiterarySourceContribution.
          ?explicitLiterarySourceContribution bf:role sorvocC:Author.
          ?explicitLiterarySourceContribution bf:agent ?explicitLiterarySourceAuthor.
          ?explicitLiterarySourceAuthor foaf:name ?explicitLiterarySourceAuthorName.
        }
      }
    }
    OPTIONAL { ?formalAppearance sor:titleStated ?titleStated. }
    OPTIONAL { ?formalAppearance sor:authorStated ?authorStated. }
    OPTIONAL { ?formalAppearance sor:hasQuotationMarks ?hasQuotationMarks. }
    OPTIONAL { 
      ?formalAppearance sor:author ?explicitAuthor.
      ?explicitAuthor foaf:name ?explicitAuthorName.
    }
  }
  FILTER(?annotation = <${annotationUri}>)
}`;

export {
  GetSingleNode,
  AnnotationsCountQuery,
  PropertiesCountQuery,
  ObjectsCountQuery,
  AllAnnotations,
  GetSingleAnnotation,
  GetSinglePerson,
  AllPersons,
  AllSourcePassages,
  GetSingleSourcePassage,
  AllResearchLiteraturePassages,
  GetSingleResearchLiteraturePassage,
  AllInstances,
  GetSingleInstance,
  AllEgotextPassages,
  GetSingleEgotextPassage,
  AllWorks,
  GetSingleWork,
  AllPlaces,
  GetSinglePlace,
  AllPublishers,
  GetSinglePublisher,
  AllInstitutions,
  GetSingleInstitution,
  AllIsbns,
  GetSingleIsbn,
  AllConcepts,
  GetSingleConcept,
  AllConceptSchemes,
  GetSingleConceptScheme,
  getAnnotationPreviewQuery,
  DeleteQuery,
  DeleteAnnotationQuery,
  DeleteEditionQuery,
  DeleteWorkQuery,
};
