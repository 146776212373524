// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from "@mui/material";
import type {ConfirmDialogProps} from "../utils/types";

  const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open,
    title,
    content,
    confirmButtonText = "Yes",
    cancelButtonText = "No",
    onCancel,
    onConfirm,
  }) => (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="inherit">
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );

  export default  ConfirmDialog;