// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  MouseEvent as ReactMouseEvent,
} from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RogerFrame from "./RogerFrame";
import TeiText from "../data/Text";
import rdfData from "../data/rdfData";
import Schema from "../data/Schema";
import ParsedXML from "./ParsedXml";
import AnnotationMetadataModal from "./AnnotationMetadataModal";
import AnnotationToc from "./AnnotationToc";
import { buildHighlightedXml } from "../utils/buildHighlightedXml";
import { mergeAnnotations } from "../utils/helpers";
import { FetchData, AnnotationReview } from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialog";
import { useSnackbar } from "../hooks/useSnackbar";

import type {
  AnnotationData,
  AnnotationItem,
  TeiXmlProps,
  AnnotationModalData,
  AnnotationPreview,
  MergedAnnotation,
  CombinedData,
  CombinedAnnotation,
} from "../utils/types";
import styled from "styled-components";
import i18next from "../i18n";
interface ShowAnnotationsButtonProps {
  active: boolean;
}

const ShowAnnotationsButton = styled.button<ShowAnnotationsButtonProps>`
  transform: rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  bottom: 47%;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.active ? "#1976d2" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#1976d2")};
  padding: 7px;
  border: 0.05px solid #1976d2;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? "#fff" : "#1976d2")};
    color: ${(props) => (props.active ? "#1976d2" : "#fff")};
  }
`;

export default function TeiXml({ config }: TeiXmlProps): JSX.Element {
  const schemaRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [annoData, setAnnoData] = useState<AnnotationItem[]>([]);
  const [annoPreview, setAnnoPreview] = useState<AnnotationPreview[]>([]);
  const [mergedAnnos, setMergedAnnos] = useState<MergedAnnotation[]>([]);
  const [highlightedXml, setHighlightedXml] = useState<string>("");
  const [showAnnotations, setShowAnnotations] = useState<boolean>(false);
  const [schemaStore, setSchemaStore] = useState<string>("");
  const [annotationData, setAnnotationData] = useState<string>("");
  const [startData, setStartData] = useState<Partial<AnnotationData>>({});
  const [endData, setEndData] = useState<Partial<AnnotationData>>({});
  const [combinedData, setCombinedData] = useState<{
    startData: Partial<AnnotationData>;
    endData: Partial<AnnotationData>;
  }>({ startData: {}, endData: {} });
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  // annotationDrawerWidth represents the RIGHT panel's width (in %)
  // The left panel (TEI text) takes the remaining width.
  const [annotationDrawerWidth, setAnnotationDrawerWidth] = useState<number>(50);
  const [menuAnchorPosition, setMenuAnchorPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [selectedText, setSelectedText] = useState<string>("");
  const [finalizeAnnotationFn, setFinalizeAnnotationFn] = useState<
    (() => void) | null
  >(null);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [pendingFinalizeFn, setPendingFinalizeFn] = useState<
    (() => void) | null
  >(null);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState<boolean>(false);
  const [disableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);
  const [annotationModalOpen, setAnnotationModalOpen] =
    useState<boolean>(false);
  const [annotationModalData, setAnnotationModalData] =
    useState<AnnotationModalData | null>(null);
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<CombinedAnnotation | null>(null);

  // Resizing state/refs for the divider:
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const dragStartXRef = useRef<number>(0);
  const startWidthRef = useRef<number>(0);

  const endpoints = {
    queryEndpoint: process.env.REACT_APP_API_SPARQL || "",
    updateEndpoint: process.env.REACT_APP_API_STATEMENTS || "",
  };

  const renderedXml =
    (showAnnotations || selectedAnnotation) && highlightedXml
      ? highlightedXml
      : TeiText;

  useEffect(() => {
    const storedPref = localStorage.getItem("showAnnotations");
    if (storedPref === "true") {
      setShowAnnotations(true);
    }
  }, []);

  useEffect(() => {
    async function fetchAnnotations() {
      try {
        const data = await FetchData();
        const preview = await AnnotationReview();
        setAnnoData(data || []);
        setAnnoPreview(preview || []);
      } catch (err) {
        console.error("Error fetching annotation data:", err);
      }
    }
    void fetchAnnotations();
  }, []);

  useEffect(() => {
    if (!annoData.length && !annoPreview.length) return;
    const merged = mergeAnnotations(annoData, annoPreview);
    setMergedAnnos(merged);
  }, [annoData, annoPreview]);

  useEffect(() => {
    let newXml = "";
    if (showAnnotations) {
      newXml = buildHighlightedXml(TeiText, annoData);
    } else if (selectedAnnotation) {
      const filteredAnnoData = annoData.filter(
        (item) => item.annotation?.value === selectedAnnotation.annotationUri
      );
      newXml = buildHighlightedXml(TeiText, filteredAnnoData);
    } else {
      newXml = TeiText;
    }
    setHighlightedXml(newXml);
  }, [showAnnotations, selectedAnnotation, annoData, annoPreview]);

  useEffect(() => {
    if (startData.dataInfo == null || endData.dataInfo == null) return;
    const newCombinedData: CombinedData = {
      startData: {
        dataInfo: startData.dataInfo,
        targetId: startData.targetId || "",
        startOffset: startData.startOffset || 0,
        endOffset: startData.endOffset || 0,
        selectedText: startData.selectedText || "",
      },
      endData: {
        dataInfo: endData.dataInfo,
        targetId: endData.targetId || "",
        startOffset: endData.startOffset || 0,
        endOffset: endData.endOffset || 0,
        selectedText: endData.selectedText || "",
      },
    };

    setSchemaStore(Schema);
    setCombinedData(newCombinedData);

    const newAnnoData = rdfData(newCombinedData);
    setAnnotationData(newAnnoData);
  }, [startData, endData]);

  useEffect(() => {
    localStorage.setItem("showAnnotations", showAnnotations ? "true" : "false");
  }, [showAnnotations]);

  // NEW: Warn user if they try to reload/navigate away while annotating.
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (drawerOpen) {
        // Prevent default and set returnValue to trigger the confirmation dialog.
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [drawerOpen]);

  // Handle icon clicks directly on the rendered HTML.
  const handleIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.classList.contains("annotation-icon")) {
      const uri = target.getAttribute("data-uri");
      if (!uri) return;
      const meta = annoPreview.find(
        (item: AnnotationPreview) => item.annotation?.value === uri
      );
      if (meta) {
        const modalData: AnnotationModalData = {
          annotationUri: meta.annotation?.value,
          exact: meta.exact?.value,
          startID: meta.startID?.value,
          endID: meta.endID?.value,
          startPos: meta.startPos?.value,
          endPos: meta.endPos?.value,
          created: meta.created?.value,
          implicitAuthorName: meta.implicitAuthorName?.value,
          explicitAuthorName: meta.explicitAuthorName?.value,
          authorStated: meta.authorStated?.value,
          titleStated: meta.titleStated?.value,
          hasQuotationMarks: meta.hasQuotationMarks?.value,
          implicitSourceWording: meta.implicitSourceWording?.value,
          explicitSourceWording: meta.explicitSourceWording?.value,
          implicitLiterarySourceMainTitle:
            meta.implicitLiterarySourceMainTitle?.value,
          explicitLiterarySourceMainTitle:
            meta.explicitLiterarySourceMainTitle?.value,
          egotextMainTitle: meta.egotextMainTitle?.value,
          egotextWording: meta.egotextWording?.value,
          researchLiteratureMainTitle:
            meta.researchLiteratureMainTitle?.value,
          researchLiteraturePassagePart:
            meta.researchLiteraturePassagePart?.value,
        };
        setAnnotationModalData(modalData);
        setAnnotationModalOpen(true);
        // Stop propagation so selection logic is not triggered.
        event.stopPropagation();
      }
    }
  };

  // When clicking inside the text, process selection (unless clicking an icon)
  const handleSelection = (event: ReactMouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).classList.contains("annotation-icon")) {
      return;
    }
    if (!window.getSelection) return;
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const rawSelectedText = selection.toString().trim();
      if (rawSelectedText) {
        const range = selection.getRangeAt(0);
        const startSeg = getTeiSegmentElement(
          range.startContainer.parentElement
        );
        const endSeg = getTeiSegmentElement(range.endContainer.parentElement);

        if (
          startSeg &&
          endSeg &&
          schemaRef.current?.contains(startSeg) &&
          schemaRef.current?.contains(endSeg)
        ) {
          const selectionStartData: AnnotationData = {
            dataInfo: decodeURIComponent(startSeg.getAttribute("sameas") || ""),
            startOffset: range.startOffset,
            targetId: startSeg.getAttribute("id") || "",
          };

          const doFinalize = () => {
            const selectedTextClean = range.toString().replace(/\n/g, "");
            const selectionEndData: AnnotationData = {
              selectedText: selectedTextClean,
              dataInfo: decodeURIComponent(endSeg.getAttribute("sameas") || ""),
              endOffset: range.endOffset,
              targetId: endSeg.getAttribute("id") || "",
            };

            setStartData(selectionStartData);
            setEndData(selectionEndData);
            // Do not reset annotationDrawerWidth so that the user's custom ratio is preserved.
            setDrawerOpen(true);
            setShowAnnotations(false);
          };

          setFinalizeAnnotationFn(() => doFinalize);
          setSelectedText(rawSelectedText);
          setMenuAnchorPosition({
            mouseX: event.clientX + 2,
            mouseY: event.clientY + 4,
          });
        }
      }
    }
  };

  const getTeiSegmentElement = (
    node: HTMLElement | null
  ): HTMLElement | null => {
    let current = node;
    while (current && current !== schemaRef.current) {
      if (current.hasAttribute("id")) {
        return current;
      }
      current = current.parentElement;
    }
    return null;
  };

  const handleDrawerClose = () => {
    setCloseConfirmOpen(true);
  };

  const handleDrawerCloseConfirmation = () => {
    setDrawerOpen(false);
    setCloseConfirmOpen(false);
  };

  const handleCopySelectedText = () => {
    if (selectedText) {
      navigator.clipboard.writeText(selectedText).catch((err) => {
        console.error("Failed to copy text:", err);
      });
    }
  };

  const handleMenuClose = () => {
    setMenuAnchorPosition(null);
  };

  const handleCreateAnnotationClick = () => {
    if (!finalizeAnnotationFn) return;
    if (drawerOpen) {
      setConfirmOpen(true);
      setPendingFinalizeFn(() => finalizeAnnotationFn);
    } else {
      if (showAnnotations || selectedAnnotation) {
        setPendingFinalizeFn(() => finalizeAnnotationFn);
        setDisableDialogOpen(true);
      } else {
        finalizeAnnotationFn();
      }
    }
    handleMenuClose();
  };

  const handleConfirmYes = () => {
    if (pendingFinalizeFn) {
      pendingFinalizeFn();
    }
    setConfirmOpen(false);
  };
  const handleConfirmNo = () => {
    setConfirmOpen(false);
  };

  const handleDisableDialogCancel = () => {
    setDisableDialogOpen(false);
    setPendingFinalizeFn(null);
  };
  const handleDisableDialogConfirm = () => {
    setDisableDialogOpen(false);
    setShowAnnotations(false);
    setSelectedAnnotation(null);
  };

  const handleGoToAnnotation = useCallback((anno: CombinedAnnotation) => {
    setShowAnnotations(false);
    setSelectedAnnotation(anno);
    if (anno.startID && schemaRef.current) {
      const segElement = schemaRef.current.querySelector(
        `#${CSS.escape(anno.startID)}`
      );
      segElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  // --- Resizing handlers ---
  // Here, annotationDrawerWidth represents the width of the RIGHT panel.
  // Dragging to the right (positive delta) should shrink the right panel.
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;
    setIsResizing(true);
    dragStartXRef.current = e.clientX;
    startWidthRef.current =
      containerRef.current.getBoundingClientRect().width *
      (annotationDrawerWidth / 100);
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isResizing || !containerRef.current) return;
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const deltaX = e.clientX - dragStartXRef.current;
      // Reverse the delta: dragging right should reduce the right panel's width.
      let newWidth = startWidthRef.current - deltaX;
      newWidth = Math.min(containerWidth - 100, Math.max(100, newWidth));
      setAnnotationDrawerWidth((newWidth / containerWidth) * 100);
    };

    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
      }
    };

    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);


  const refreshAnnotations = async () => {
    try {
      const data = await FetchData();
      const preview = await AnnotationReview();
      setAnnoData(data || []);
      setAnnoPreview(preview || []);
      const merged = mergeAnnotations(data || [], preview || []);
      if (merged.length) {
        const newest = merged.reduce((prev, curr) => {
          return new Date(curr.created || 0).getTime() > new Date(prev.created || 0).getTime() ? curr : prev;
        });
        setDrawerOpen(false);
        handleGoToAnnotation(newest);
        showSnackbar("Annotation created successfully!", "success");
      }
    } catch (err) {
      console.error("Error fetching annotation data:", err);
    }
  };

  return (
    <>
      <Container maxWidth="xl" sx={{
        position: "fixed",
        top: 100,
        bottom: 40,
        padding: "2rem",
        overflow: "auto",
        mt:2,
      }}>
        <AnnotationToc
          annotationList={mergedAnnos}
          onGoTo={handleGoToAnnotation}
        />

        <ShowAnnotationsButton
          active={showAnnotations}
          disabled={drawerOpen}
          onClick={() => {
            setShowAnnotations(!showAnnotations);
            if (!showAnnotations) {
              setSelectedAnnotation(null);
            }
          }}
        >
          {showAnnotations ? "✓ Show Annotations" : "Show Annotations"}
        </ShowAnnotationsButton>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAnnotations}
              onChange={(e) => {
                setShowAnnotations(e.target.checked);
                if (e.target.checked) {
                  setSelectedAnnotation(null);
                }
              }}
              color="primary"
              disabled={drawerOpen}
            />
          }
          label="Show Annotations"
          sx={{ mb: 2 }}
        />

        {drawerOpen ? (
          <Box
            ref={containerRef}
            sx={{
              display: "flex",
              width: "100%",
              height: "calc(100vh - 4rem)",
            }}
          >
            {/* LEFT panel: TEI XML Content */}
            <Box
              sx={{
                width: `${100 - annotationDrawerWidth}%`,
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "8px",
                border: "1px solid #80808070",
                overflowY: "auto",
              }}
            >
              <div
                ref={schemaRef}
                onClick={handleIconClick}
                onMouseUp={handleSelection}
                dangerouslySetInnerHTML={{ __html: renderedXml }}
                style={{ height: "100%", overflowY: "auto" }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleIconClick(e as unknown as React.MouseEvent<HTMLDivElement>);
                  }
                }}
              />
              <ParsedXML xml={TeiText} />
            </Box>
            {/* Resizable Divider with Handle */}
            <Box
              sx={{
                width: "20px",
                cursor: "col-resize",
                userSelect: "none",
                backgroundColor: "#f0f0f0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseDown={handleMouseDown}
            >
              <DragIndicatorIcon sx={{ color: "#888" }} />
            </Box>
            {/* RIGHT panel: Annotation Creation Drawer */}
            <Box
              sx={{
                width: `${annotationDrawerWidth}%`,
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "8px",
                border: "1px solid #80808070",
                overflowY: "auto",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      You have selected
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "4px",
                        padding: "0.4rem 0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      {combinedData.endData?.selectedText || "No text selected"}
                    </Box>
                  </Box>
                  <Button
                    onClick={handleDrawerClose}
                    variant="outlined"
                    sx={{
                      cursor: "pointer",
                      padding: "0.3rem 0.5rem",
                      marginLeft: "1.5rem",
                      borderRadius: "8px",
                      transition: "background-color 0.3s",
                      "&:hover": { backgroundColor: "primary" },
                      my: 1.5,
                    }}
                  >
                    Close
                  </Button>
                </Box>
                <RogerFrame
                  key={annotationData}
                  initialQuads={annotationData}
                  schema={schemaStore}
                  config={config}
                  endpoints={endpoints}
                  onAnnotationCreated={() => { refreshAnnotations().catch(console.error); }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "8px",
              border: "1px solid #80808070",
              overflowY: "auto",
              height: "calc(100vh - 4rem)",
            }}
          >
            <div
              ref={schemaRef}
              onClick={handleIconClick}
              onMouseUp={handleSelection}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleIconClick(e as unknown as React.MouseEvent<HTMLDivElement>);
                }
              }}
              dangerouslySetInnerHTML={{ __html: renderedXml }}
              style={{ height: "100%", overflowY: "auto" }}
              role="button"
              tabIndex={0}
            />
            <ParsedXML xml={TeiText} />
          </Box>
          
        )}
        {SnackbarComponent}
      </Container>

      <Menu
        open={Boolean(menuAnchorPosition)}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          menuAnchorPosition
            ? {
                top: menuAnchorPosition.mouseY,
                left: menuAnchorPosition.mouseX,
              }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            handleCopySelectedText();
            handleMenuClose();
          }}
        >
          Copy
        </MenuItem>
        <MenuItem onClick={handleCreateAnnotationClick}>
          Create Annotation
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={confirmOpen}
        title="Are you sure?"
        content="You are about to start creating a new annotation. The previous annotation data will be lost. Do you want to continue?"
        onCancel={handleConfirmNo}
        onConfirm={handleConfirmYes}
      />

      <ConfirmDialog
        open={closeConfirmOpen}
        title="Close Annotation?"
        content="Are you sure you want to close and discard any unsaved annotation data?"
        onCancel={() => setCloseConfirmOpen(false)}
        onConfirm={handleDrawerCloseConfirmation}
      />

      <ConfirmDialog
        open={disableDialogOpen}
        title="Disable Highlights?"
        content='To create a new annotation, you must disable "Show Annotations" and de-highlight any active annotation.'
        confirmButtonText="Disable"
        onCancel={handleDisableDialogCancel}
        onConfirm={handleDisableDialogConfirm}
      />

      <AnnotationMetadataModal
        open={annotationModalOpen}
        onClose={() => setAnnotationModalOpen(false)}
        metadata={annotationModalData}
      />
    </>
  );
}
