// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import type {AnnotationPreviewObject} from "../utils/types";

interface AnnotationMetadataModalProps {
  open: boolean;
  onClose: () => void;
  metadata: AnnotationPreviewObject | null;
}

export default function AnnotationMetadataModal({
  open,
  onClose,
  metadata,
}: AnnotationMetadataModalProps) {

  const fields = [
    { label: "Exact Text", value: metadata?.exact },
    { label: "Created", value: metadata?.created ? new Date(metadata.created).toLocaleString() : "N/A" },
    { label: "Implicit Author Name", value: metadata?.implicitAuthorName },
    { label: "Explicit Author Name", value: metadata?.explicitAuthorName },
    { label: "Title Stated", value: metadata?.titleStated },
    { label: "Author Stated", value: metadata?.authorStated },
    { label: "Has Quotation Marks", value: metadata?.hasQuotationMarks },
    {
      label: "Implicit Source Wording",
      value: metadata?.implicitSourceWording,
    },
    {
      label: "Explicit Source Wording",
      value: metadata?.explicitSourceWording,
    },
    {
      label: "Implicit Literary Source Main Title",
      value: metadata?.implicitLiterarySourceMainTitle,
    },
    {
      label: "Explicit Literary Source Main Title",
      value: metadata?.explicitLiterarySourceMainTitle,
    },
    { label: "Egotext Main Title", value: metadata?.egotextMainTitle }, 
    { label: "Egotext Wording", value: metadata?.egotextWording },
    {
      label: "Research Literature Main Title",
      value: metadata?.researchLiteratureMainTitle,
    },
    {
      label: "Research Literature Passage Part",
      value: metadata?.researchLiteraturePassagePart,
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Annotation Metadata</DialogTitle>
      <DialogContent dividers>
        {!metadata ? (
          <Typography variant="body2">No metadata found.</Typography>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {fields.map((field) => (
              <Typography variant="body2" key={field.label}>
                <strong>{field.label}:</strong> {field.value}
              </Typography>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
