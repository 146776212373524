// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import * as React from "react";
import Stack from "@mui/material/Stack";
import { Roger } from "@fe/roger-core";
import type { Quad } from "@rdfjs/types";
import type { SparqlConfig } from "./RdfStoreHandlers";
import { addResource } from "./RdfStoreHandlers";
import { updateResource } from "./RdfStoreHandlers";
import { useSnackbar } from "../hooks/useSnackbar";

interface RogerFrameProps {
  schema: string;
  idNamespace?: string;
  config?: SparqlConfig;
  initialQuads: string;
  endpoints: {};
  onSave?: (response: any) => void;
  mode: "create" | "edit";
}

export default function RogerFrame({
  schema,
  config,
  initialQuads,
  endpoints,
  onSave,
  mode,
}: RogerFrameProps) {
  const [changed, setChanged] = React.useState<boolean>(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  async function handleSave(initialQuads: Quad[]) {
    if (onSave) {
      try {
        if (mode === "create") {
          const response = await addResource(initialQuads, config);
          console.log("response", response);
          onSave(response);
        } else if (mode === "edit") {
          const response = await updateResource(initialQuads, config);
          onSave(response);
        } else {
          showSnackbar("Invalid mode", "error");
        }
      } catch (error) {
        showSnackbar("Failed to update resource", "error");
        console.error("Error saving resource:", error);
      }
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Roger
        schema={schema}
        data={initialQuads}
        onSave={config ? handleSave : undefined}
        useChanged={[changed, setChanged]}
        endpoints={endpoints}
      />
      {SnackbarComponent}
    </Stack>
  );
}
