// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  ItalicOutlined,
  SearchOutlined,
  UserOutlined,
  FullscreenExitOutlined,
  AlignCenterOutlined,
  BookOutlined,
  CopyOutlined,
  ClusterOutlined,
  ProfileOutlined,
  SolutionOutlined,
  BankOutlined,
  GlobalOutlined,
  BulbOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  ItalicOutlined,
  SearchOutlined,
  UserOutlined,
  FullscreenExitOutlined,
  AlignCenterOutlined,
  BookOutlined,
  CopyOutlined,
  ClusterOutlined,
  ProfileOutlined,
  SolutionOutlined,
  BankOutlined,
  GlobalOutlined,
  BulbOutlined,
  FileProtectOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const search = {
  id: "search",
  title: "Search",
  type: "group",
  children: [
    {
      id: "search-annotation",
      title: "Annotations",
      type: "item",
      url: "/searchannotation",
      icon: icons.ItalicOutlined,
    },
    {
      id: "search-person",
      title: "Persons",
      type: "item",
      url: "/searchperson",
      icon: icons.UserOutlined,
    },
    {
      id: "search-source",
      title: "Source Passages",
      type: "item",
      url: "/searcsource",
      icon: icons.FullscreenExitOutlined,
    },
    {
      id: "search-research",
      title: "Research Literature Passages",
      type: "item",
      url: "/searchresearch",
      icon: icons.ProfileOutlined,
    },
    {
      id: "search-egotext",
      title: "Egotext Passages",
      type: "item",
      url: "/searchegotext",
      icon: icons.AlignCenterOutlined,
    },
    {
      id: "search-instance",
      title: "Editions",
      type: "item",
      url: "/searchinstance",
      icon: icons.ClusterOutlined,
    },
    {
      id: "search-concept",
      title: "Concepts",
      type: "item",
      url: "/searchconcept",
      icon: icons.BulbOutlined,
    },
    {
      id: "search-ceptscheme",
      title: "Concept Schemes",
      type: "item",
      url: "/searchconceptscheme",
      icon: icons.FileProtectOutlined,
    },
  ],
};

export default search;
