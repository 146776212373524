// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2
import React, { useState, useMemo } from "react";
import {
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import type {CombinedAnnotation} from "../utils/types";

interface AnnotationTocProps {
  annotationList: CombinedAnnotation[];
  onGoTo: (anno: CombinedAnnotation) => void;
}

export default function AnnotationToc({
  annotationList,
  onGoTo,
}: AnnotationTocProps) {
  // State for controlling the drawer open/close.
  const [drawerOpen, setDrawerOpen] = useState(false);
  // State for the search query.
  const [searchQuery, setSearchQuery] = useState("");
  // State for sort option.
  const [sortOption, setSortOption] = useState<string>("exact-asc");

  // Styled button rotated for toggling the drawer.
  const RotatedButton = styled.button`
    transform: rotate(-90deg);
    transform-origin: bottom right;
    position: fixed;
    bottom:63%;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1976d2;
    color: #fff;
    padding: 7px;
    border: 0.05px solid #1976d2;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      background-color: #fff;
      color: #1976d2;
      transition: background-color 0.3s, color 0.3s;
      border: 0.05px solid #1976d2;
    }
  `;

  // Toggle the drawer open/close.
  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Compute the filtered and sorted annotations.
  // Filtering is based on whether the "exact" text includes the search query (case-insensitive).
  // Sorting is applied based on the chosen sort option.
  const filteredAnnotations = useMemo(() => {
    const filtered = annotationList.filter((anno) =>
      (anno.exact || "").toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filtered.sort((a, b) => {
      switch (sortOption) {
        case "exact-asc":
          return (a.exact || "").localeCompare(b.exact || "");
        case "exact-desc":
          return (b.exact || "").localeCompare(a.exact || "");
          case "created-asc":
            return (a.created ? new Date(a.created).getTime() : 0) -
                   (b.created ? new Date(b.created).getTime() : 0);
          case "created-desc":
            return (b.created ? new Date(b.created).getTime() : 0) -
                   (a.created ? new Date(a.created).getTime() : 0);
        default:
          return 0;
      }
    });
  }, [annotationList, searchQuery, sortOption]);

  const fields = (metadata: CombinedAnnotation) =>{
    return [
      { label: "Exact Text", value: metadata?.exact },
      { label: "Created", value: metadata.created ? new Date(metadata.created).toLocaleString() : "N/A" },
      { label: "Implicit Author Name", value: metadata?.implicitAuthorName },
      { label: "Explicit Author Name", value: metadata?.explicitAuthorName },
      { label: "Title Stated", value: metadata?.titleStated },
      { label: "Author Stated", value: metadata?.authorStated },
      { label: "Has Quotation Marks", value: metadata?.hasQuotationMarks },
      {
        label: "Implicit Source Wording",
        value: metadata?.implicitSourceWording,
      },
      {
        label: "Explicit Source Wording",
        value: metadata?.explicitSourceWording,
      },
      {
        label: "Implicit Literary Source Main Title",
        value: metadata?.implicitLiterarySourceMainTitle,
      },
      {
        label: "Explicit Literary Source Main Title",
        value: metadata?.explicitLiterarySourceMainTitle,
      },
      { label: "Egotext Main Title", value: metadata?.egotextMainTitle }, 
      { label: "Egotext Wording", value: metadata?.egotextWording },
      {
        label: "Research Literature Main Title",
        value: metadata?.researchLiteratureMainTitle,
      },
      {
        label: "Research Literature Passage Part",
        value: metadata?.researchLiteraturePassagePart,
      },
    ];
  } 
  
  return (
    <>
      <RotatedButton onClick={handleToggleDrawer}>
        Annotations List
      </RotatedButton>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleToggleDrawer}
        PaperProps={{ sx: { width: 400, mt:7 } }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Annotation Table of Contents
          </Typography>
          {/* Search field for filtering by "exact" */}
          <TextField
            fullWidth
            placeholder="Search by text..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            margin="normal"
            variant="outlined"
            size="small"
          />

          {/* Dropdown for sort options */}
          <TextField
            fullWidth
            select
            label="Sort By"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            margin="normal"
            variant="outlined"
            size="small"
          >
            <MenuItem value="exact-asc">Exact (A - Z)</MenuItem>
            <MenuItem value="exact-desc">Exact (Z - A)</MenuItem>
            <MenuItem value="created-asc">Created (Oldest First)</MenuItem>
            <MenuItem value="created-desc">Created (Newest First)</MenuItem>
          </TextField>

          {/* Render filtered and sorted annotations */}
          {filteredAnnotations.map((anno, index) => {
            const shortText = anno.exact ? anno.exact.slice(0, 30) : "No text";
            const fieldss = fields(anno);
            return (
              <Accordion key={`${anno.annotationUri || ""}-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {shortText}...
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {fieldss.map((field) => (
                    <>
                    <Typography variant="body2" key={field.label}>
                      <strong>{field.label}:</strong> {field.value}
                    </Typography>
                    </>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      onGoTo(anno);
                      setDrawerOpen(false);
                    }}
                    sx={{ mt: 1 }}
                  >
                    Locate in Text
                  </Button>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Drawer>
    </>
  );
}
