// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
interface Interval {
    start: number;
    end: number;
    exact: string;
    annotationUri: string;
  }

interface AnnoData {
    startID?: { value: string };
    endID?: { value: string };
    startPos?: { value: string };
    endPos?: { value: string };
    exact?: { value: string };
    annotation?: { value: string };
  }
  
  /**
   * Builds a single HTML string with coverage highlights and annotation icons.
   * 
   * @param xml - The XML string to process.
   * @param annoData - Array of annotation data.
   * @returns The processed HTML string.
   */
  export function buildHighlightedXml(
    xml: string,
    annoData: AnnoData[],
  ): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "text/html");
    const container = doc.body || doc;
    const segmentMap: Record<string, Interval[]> = {};
  
    // Process each annotation
    annoData.forEach((anno) => {
      const startID = anno.startID?.value;
      const endID = anno.endID?.value;
      if (!startID || !endID) return;
  
      const startPos = parseInt(anno.startPos?.value ?? "0", 10);
      const endPos = parseInt(anno.endPos?.value ?? "0", 10);
      const exactText = anno.exact?.value || "";
      const annotationUri = anno.annotation?.value || "";
  
      if (startID === endID) {
        addInterval(segmentMap, startID, { start: startPos, end: endPos, exact: exactText, annotationUri });
      } else {
        const startElement = container.querySelector(`#${CSS.escape(startID)}`);
        const endElement = container.querySelector(`#${CSS.escape(endID)}`);
        if (!startElement || !endElement) return;
  
        // For the start element, highlight from startPos to end of text
        const startText = startElement.textContent || "";
        addInterval(segmentMap, startID, { start: startPos, end: startText.length, exact: exactText, annotationUri });
  
        // For the end element, highlight from beginning to endPos
        addInterval(segmentMap, endID, { start: 0, end: endPos, exact: exactText, annotationUri });
      }
    });
  
    // Update each segment with highlighted text and icons
    Object.entries(segmentMap).forEach(([segmentId, intervals]) => {
      const segmentElement = container.querySelector(`#${CSS.escape(segmentId)}`);
      if (!segmentElement?.firstChild) return;
  
      const text = segmentElement.firstChild.textContent || "";
      segmentElement.innerHTML = buildSegmentHtml(text, intervals);
    });
  
    return container.innerHTML;
  }
  
  /**
   * Adds an interval to the segment map.
   */
  function addInterval(
    segmentMap: Record<string, Interval[]>,
    segmentId: string,
    interval: Interval
  ): void {
    if (!segmentMap[segmentId]) {
      segmentMap[segmentId] = [];
    }
    segmentMap[segmentId].push(interval);
  }
  
  /**
   * Processes the text for a segment, applying highlights based on annotation coverage,
   * and inserting annotation icons where needed.
   * 
   * @param text - The text content of the segment.
   * @param intervals - The annotation intervals for this segment.
   * @returns The HTML string with highlights and icons.
   */
  function buildSegmentHtml(
    text: string,
    intervals: Interval[],
  ): string {
    const textLength = text.length;
    const coverage = new Array<number>(textLength).fill(0);
    const startEvents: { pos: number; exact: string; annotationUri: string }[] = [];
  
    // Apply each interval to the coverage array and record start events.
    intervals.forEach((interval) => {
      const start = Math.max(0, Math.min(interval.start, textLength));
      const end = Math.max(0, Math.min(interval.end, textLength));
  
      for (let i = start; i < end; i++) {
        coverage[i]++;
      }
      startEvents.push({ pos: start, exact: interval.exact, annotationUri: interval.annotationUri });
    });
  
    // Sort start events by position.
    startEvents.sort((a, b) => a.pos - b.pos);
  
    let result = "";
    let pos = 0;
    let eventIndex = 0;
  
    while (pos < textLength) {
      // Insert annotation icon(s) if any event starts at the current position.
      while (eventIndex < startEvents.length && startEvents[eventIndex].pos === pos) {
        result += createAnnotationIcon(startEvents[eventIndex].annotationUri);
        eventIndex++;
      }
  
      // Process a continuous run of text with the same coverage value.
      if (coverage[pos] === 0) {
        const runStart = pos;
        while (pos < textLength && coverage[pos] === 0) pos++;
        result += escapeHtml(text.slice(runStart, pos));
      } else if (coverage[pos] === 1) {
        const runStart = pos;
        while (pos < textLength && coverage[pos] === 1) pos++;
        result += `<span style="background-color: yellow; border-radius: 2px; padding: 0 2px;">${escapeHtml(text.slice(runStart, pos))}</span>`;
      } else {
        // For overlapping annotations (coverage >= 2)
        const runStart = pos;
        const currentCoverage = coverage[pos];
        while (pos < textLength && coverage[pos] === currentCoverage) pos++;
        result += `<span style="background-color: orange; border-radius: 2px; padding: 0 2px;">${escapeHtml(text.slice(runStart, pos))}</span>`;
      }
    }
  
    return result;
  }
  
  /**
   * Creates the HTML for an annotation icon.
   *
   * @param annotationUri - The URI associated with the annotation.
   * @returns The HTML string for the annotation icon.
   */
  function createAnnotationIcon(annotationUri: string): string {
    return `<span class="annotation-icon" style="color: blue; cursor: pointer; margin-right: 2px;" data-uri="${escapeHtml(annotationUri)}">&#9432;</span>`;
  }
  
  /**
   * Escapes special HTML characters in a string.
   *
   * @param str - The string to escape.
   * @returns The escaped string.
   */
  function escapeHtml(str: string): string {
    return str
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }