// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import SingleFrame from "./RogerFrame";
import { useAppContext } from "../context/AppContext";
import { useSnackbar } from "../hooks/useSnackbar";

interface AddResourceProps {
  getNewInitialQuads: () => Promise<string>;
  onSaveComplete: () => void;
  buttonLabel: string;
}

const AddResource: React.FC<AddResourceProps> = ({
  getNewInitialQuads,
  onSaveComplete,
  buttonLabel,
}) => {
  const { endpoints, config, schema } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [initialQuads, setInitialQuads] = useState<string>("");
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const handleDialogOpen = async () => {
    const newInitialQuads = await getNewInitialQuads();
    setInitialQuads(newInitialQuads);
    setOpenDialog(true);
  };

  const handleDialogClose = () => setOpenDialog(false);

  const handleSave = async (response) => {
    onSaveComplete();
    showSnackbar(response.message, "success");
    handleDialogClose();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="edit-dialog-title"
      >
        <DialogContent>
          <SingleFrame
            schema={schema}
            initialQuads={initialQuads}
            endpoints={endpoints}
            config={config}
            onSave={handleSave}
            mode="create"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={handleDialogOpen}>
        {buttonLabel}
      </Button>
      {SnackbarComponent}
    </>
  );
};

export default AddResource;
