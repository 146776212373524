// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import { AxiosResponse } from "axios";
import axios from "axios";
import Prefixes from "./Prefixes";
import type { AnnotationItem, AnnotationPreview, MergedAnnotation, SparqlBinding, SparqlResponse, Request, AnnotationData } from "./types";

const anottationPositionQuery = `SELECT ?annotation ?target ?exact ?created
       ?startPos ?endPos ?startID ?endID
       ?startXpath ?endXpath
WHERE {
  ?annotation a oa:Annotation ;
              dct:created ?created .

  OPTIONAL {
    ?annotation oa:hasTarget ?target .
    ?target a oa:SpecificResource ;
            oa:hasSelector ?rangeSelector .

    ?rangeSelector a oa:RangeSelector .

    # -- If there's a TextQuoteSelector, retrieve the exact text --
    OPTIONAL {
      ?rangeSelector oa:refinedBy ?textQuoteSelector .
      ?textQuoteSelector a oa:TextQuoteSelector ;
                         oa:exact ?exact .
    }

    # -- If there's a DataPositionSelector, retrieve the start/end offsets --
    OPTIONAL {
      ?rangeSelector oa:refinedBy ?posSelector .
      ?posSelector a oa:DataPositionSelector ;
                   oa:start ?startPos ;
                   oa:end   ?endPos .
    }

    # -- If there's an XMLIDSelector, retrieve the startID/endID --
    OPTIONAL {
      ?rangeSelector oa:refinedBy ?xmlIdSelector .
      ?xmlIdSelector a sor:XMLIDSelector ;
                     sor:startID ?startID ;
                     sor:endID   ?endID .
    }

    # -- If we use oa:hasStartSelector/oa:hasEndSelector for XPath, gather them too --
    OPTIONAL {
      ?rangeSelector oa:hasStartSelector ?startSelector ;
                     oa:hasEndSelector   ?endSelector .
      ?startSelector a oa:XPathSelector ;
                     rdf:value ?startXpath .
      ?endSelector   a oa:XPathSelector ;
                     rdf:value ?endXpath .
    }
  }
}`;

const AnnotationPreviewQuery = `select distinct ?annotation ?exact ?created ?creator ?referenceTypePrefLabel ?hasQuotationMarks ?authorStated ?explicitAuthorName ?titleStated ?explicitSourceWording ?explicitLiterarySourceAuthorName ?explicitLiterarySourceMainTitle ?implicitSourceWording ?implicitLiterarySourceAuthorName ?implicitLiterarySourceMainTitle ?implicitAuthorName ?researchLiteraturePassagePart ?researchLiteratureAuthorName ?researchLiteratureMainTitle ?egotextWording ?egotextMainTitle ?seeAlso
{
?annotation a oa:Annotation;
    oa:hasTarget ?specificResource;
    dct:created ?created;
    oa:hasBody ?body.

    ?specificResource a oa:SpecificResource;
    oa:hasSelector ?rangeSelector.

    ?rangeSelector a oa:RangeSelector;
    oa:refinedBy ?quoteSelector.

    ?quoteSelector a oa:TextQuoteSelector;
    oa:exact ?exact.

    ?body intro:R21_identifies ?intertextualRelationship.
    # ?intertextualRelationship intro:R19_has_type ?referenceType.
    # ?referenceType skos:prefLabel ?referenceTypePrefLabel.
    
    OPTIONAL {?annotation dct:creator ?creator.}
    OPTIONAL {?annotation sor:seeAlso ?seeAlso.}
    
    OPTIONAL { ?body sor:literarySource ?implicitLiterarySourcePassage.
               ?implicitLiterarySourcePassage intro:R44_has_wording ?implicitSourceWording.
               OPTIONAL { ?implicitLiterarySourcePassage bf:partOf ?implicitLiterarySourceInstance.
                          ?implicitLiterarySourceInstance bf:title ?implicitLiterarySourceTitle.
                          ?implicitLiterarySourceTitle bf:mainTitle ?implicitLiterarySourceMainTitle.
                          OPTIONAL { ?implicitLiterarySourcePassage bf:contribution ?implicitLiterarySourceContribution.
                                     ?implicitLiterarySourceContribution bf:role sorvocC:Author.
                                     ?implicitLiterarySourceContribution bf:agent ?implicitLiterarySourceAuthor.
                                     ?implicitLiterarySourceAuthor foaf:name ?implicitLiterarySourceAuthorName.
                                   }
                        }
               }
    OPTIONAL { ?body sor:notedIn ?researchLiteraturePassage.
               ?researchLiteraturePassage bf:part ?researchLiteraturePassagePart.
               OPTIONAL { ?researchLiteraturePassage bf:partOf ?researchLiteratureInstance.
                          ?researchLiteratureInstance bf:title ?researchLiteratureTitle.
                          ?researchLiteratureTitle bf:mainTitle ?researchLiteratureMainTitle.
                          OPTIONAL { ?researchLiteraturePassage bf:contribution ?researchLiteratureContribution.
                                     ?researchLiteratureContribution bf:role sorvocC:Author.
                                     ?researchLiteratureContribution bf:agent ?researchLiteratureAuthor.
                                     ?researchLiteratureAuthor foaf:name ?researchLiteratureAuthorName.
                                   }
                        }
             }
    OPTIONAL { ?body sor:egotext ?egotextPassage.
               ?egotextPassage intro:R44_has_wording ?egotextWording.
               OPTIONAL { ?egotextPassage bf:partOf ?egotextInstance.
                          ?egotextInstance bf:title ?egotextTitle.
                          ?egotextTitle bf:mainTitle ?egotextMainTitle.
                        }
             }
    OPTIONAL { ?body sor:note ?note.}
    OPTIONAL { ?body sor:author ?implicitAuthor.
               ?implicitAuthor foaf:name ?implicitAuthorName.}
               
    OPTIONAL { ?intertextualRelationship sor:hasFormalAppearance ?formalAppearance.
               OPTIONAL { ?formalAppearance sor:literarySource ?explicitLiterarySourcePassage.
                          ?explicitLiterarySourcePassage intro:R44_has_wording ?explicitSourceWording.
                          OPTIONAL { ?explicitLiterarySourcePassage bf:partOf ?explicitLiterarySourceInstance.
                                     ?explicitLiterarySourceInstance bf:title ?explicitLiterarySourceTitle.
                                     ?explicitLiterarySourceTitle bf:mainTitle ?explicitLiterarySourceMainTitle.
                                     OPTIONAL { ?explicitLiterarySourcePassage bf:contribution ?explicitLiterarySourceContribution.
                                                ?explicitLiterarySourceContribution bf:role sorvocC:Author.
                                                ?explicitLiterarySourceContribution bf:agent ?explicitLiterarySourceAuthor.
                                                ?explicitLiterarySourceAuthor foaf:name ?explicitLiterarySourceAuthorName.
                                              }
                                   }
                        }
              OPTIONAL { ?formalAppearance sor:titleStated ?titleStated.}
              OPTIONAL { ?formalAppearance sor:authorStated ?authorStated.}
              OPTIONAL { ?formalAppearance sor:hasQuotationMarks ?hasQuotationMarks.}
              OPTIONAL { ?formalAppearance sor:author ?explicitAuthor.
                         ?explicitAuthor foaf:name ?explicitAuthorName.
                       }
             }
}`;

export const Fetcher = async (Request: Request) => {
    try {
      const query = `
          ${Prefixes}
          ${Request.query}
          `;
      const headers = Request.headers;
      const response = await axios.post(Request.url, query, { headers });
      return response;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
};

export const FetchData = async (): Promise<SparqlBinding[]> => {
  const Request = {
    query: anottationPositionQuery,
    headers: {
      "Content-Type": "application/sparql-query",
    },
    url: `${process.env.REACT_APP_API_SPARQL || "default_url"}`,
  };
  const result = await Fetcher(Request);
  if ((result as AxiosResponse<SparqlResponse>).data) {
    const axiosResponse = result as AxiosResponse<SparqlResponse>;
    const bindings: SparqlBinding[] = axiosResponse?.data.results.bindings;
    return bindings;
  }
  return [];
};

export const AnnotationReview = async (): Promise<SparqlBinding[]> => {
  const Request = {
    query: AnnotationPreviewQuery,
    headers: {
      "Content-Type": "application/sparql-query",
    },
    url: `${process.env.REACT_APP_API_SPARQL || ""}`,
  };
  const result = await Fetcher(Request);
  if ((result as AxiosResponse<SparqlResponse>).data) {
    const axiosResponse = result as AxiosResponse<SparqlResponse>;
    const bindings: SparqlBinding[] = axiosResponse?.data.results.bindings;
    return bindings;
  }
  return [];
};

export function mergeAnnotations(
  annoData: AnnotationItem[],
  annoPreview: AnnotationPreview[]
): MergedAnnotation[] {
  return annoData.map((annoItem) => {
    const uri = annoItem.annotation?.value;
    // Find the matching preview item based on the annotation URI.
    const previewMatch = annoPreview.find(
      (preview) => preview.annotation?.value === uri
    );

    return {
      // Coverage fields
      annotationUri: uri,
      exact: annoItem.exact?.value || "",
      startID: annoItem.startID?.value,
      endID: annoItem.endID?.value,
      startPos: annoItem.startPos?.value,
      endPos: annoItem.endPos?.value,
      created: annoItem.created?.value,
      // Metadata fields
      implicitAuthorName: previewMatch?.implicitAuthorName?.value,
      explicitAuthorName: previewMatch?.explicitAuthorName?.value,
      authorStated: previewMatch?.authorStated?.value,
      titleStated: previewMatch?.titleStated?.value,
      hasQuotationMarks: previewMatch?.hasQuotationMarks?.value,
      implicitSourceWording: previewMatch?.implicitSourceWording?.value,
      explicitSourceWording: previewMatch?.explicitSourceWording?.value,
      implicitLiterarySourceMainTitle: previewMatch?.implicitLiterarySourceMainTitle?.value,
      explicitLiterarySourceMainTitle: previewMatch?.explicitLiterarySourceMainTitle?.value,
      egotextMainTitle: previewMatch?.egotextMainTitle?.value,
      egotextWording: previewMatch?.egotextWording?.value,
      researchLiteratureMainTitle: previewMatch?.researchLiteratureMainTitle?.value,
      researchLiteraturePassagePart: previewMatch?.researchLiteraturePassagePart?.value,
    };
  });
}