// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem, useMediaQuery, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Search from "./Search";
import Profile from "./Profile";
import MobileSection from "./MobileSection";

export default function HeaderContent() {
  const { i18n } = useTranslation();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);

  // On mount, check if a language is stored in localStorage
  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage && storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("selectedLanguage", lang);
    setAnchorEl(null);
  };

  return (
    <>
      {!downLG && <Search />}
      <Button onClick={handleLanguageClick} variant="outlined" sx={{ mr: 2 }}>
        {i18n.language.toUpperCase()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleLanguageClose}
      >
        <MenuItem onClick={() => handleLanguageSelect("en")}>EN</MenuItem>
        <MenuItem onClick={() => handleLanguageSelect("de")}>DE</MenuItem>
      </Menu>
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
}
