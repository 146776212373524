// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  IconButton,
  Typography,
  Link,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Preview Icon
import { DataGrid, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid";
import {
  GetSingleAnnotation,
  AllAnnotations,
  DeleteAnnotationQuery,
  getAnnotationPreviewQuery
} from "../../data/Queries";
import { AxiosResponse } from "axios";
import RogerFrame from "../../utils/RogerFrame";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import {
  FetchData,
  DeleteAnnotation,
} from "../../utils/helpers";
import { FetchData as fetchSparql } from "../../api/FetchData";
import { useAppContext } from "../../context/AppContext";
import { Link as RouterLink } from "react-router-dom";

interface Binding {
  annotation: { value: string };
  exact?: { value: string };
  created?: { value: string };
}

interface ParsedBinding {
  id: number;
  annotation: string;
  exact: string | null;
  created: string | null;
}

const parser = (bindings: Binding[]): ParsedBinding[] => {
  return bindings.map((binding, index) => ({
    id: index,
    annotation: binding.annotation.value,
    exact: binding.exact?.value ?? null,
    created: binding.created?.value ?? null,
  }));
};

const Annotations: React.FC<{ refresh: boolean }> = ({ refresh }) => {
  const { endpoints, config, schema } = useAppContext();
  const [annotations, setAnnotations] = useState<ParsedBinding[]>([]);
  const [editQuads, setEditQuads] = useState("empty");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { showConfirmDialog, ConfirmDialogComponent } = useConfirmDialog();
  // State for metadata dialog
  const [metadataDialogOpen, setMetadataDialogOpen] = useState(false);
  const [metadataData, setMetadataData] = useState<ParsedBinding | null>(null);

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const fetchData = async () => {
    const bindings = await FetchData(AllAnnotations);
    const annotations = parser(bindings);
    setAnnotations(annotations);
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const handleEdit = async (uri: string) => {
    try {
      const query = GetSingleAnnotation(uri);
      const Request = {
        query: query,
        headers: {
          "Content-Type": "application/sparql-query",
        },
        url: `${process.env.REACT_APP_API_SPARQL}`,
      };
      const result = await fetchSparql(Request);
      if ((result as AxiosResponse).data) {
        const axiosResponse = result as AxiosResponse;
        setEditQuads(axiosResponse.data);
        handleDialogOpen();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = (uri: string) => {
    showConfirmDialog("Are you sure you want to delete this annotation?", () =>
      confirmDelete(uri)
    );
  };

  const confirmDelete = async (uri: string) => {
    const query = DeleteAnnotationQuery(uri);
    const response = await DeleteAnnotation(query);
    if ((response as AxiosResponse).status === 204) {
      showSnackbar("Annotation deleted successfully!", "success");
      fetchData();
    } else {
      showSnackbar("Failed to delete annotation", "error");
    }
  };

  const onSave = async (response: any) => {
    await fetchData();
    handleDialogClose();
    showSnackbar(response.message, "success");
  };

  const handleShowMetadata = async (row: ParsedBinding) => {
    try {
      const query = getAnnotationPreviewQuery(row.annotation);
      const Request = {
        query,
        headers: {
          "Content-Type": "application/sparql-query",
        },
        url: `${process.env.REACT_APP_API_SPARQL || ""}`,
      };
      const result = await fetchSparql(Request);
      const axiosResponse = result as AxiosResponse;
      const bindings = axiosResponse?.data.results.bindings;
      console.log("bindings", bindings);
      if (bindings && bindings.length > 0) {
        setMetadataData(bindings[0]);
      } else {
        setMetadataData(null);
      }
      setMetadataDialogOpen(true);
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };

  const columns = [
    {
      field: "exact",
      headerName: "Text",
      flex: 1,
      renderCell: (params: any) => (
        <Typography
          variant="body1"
          onClick={() => handleShowMetadata(params.row)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            pl: 1,
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      renderCell: (params: any) => {
        let value = params.value;
        if (value && typeof value === "object" && "value" in value) {
          value = value.value;
        }
        const formatted =
          value && !isNaN(new Date(value).getTime())
            ? new Date(value).toLocaleString()
            : "N/A";
        return (
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
              pl: 1,
            }}
          >
            {formatted}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params: any) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          width="100%"
        >
          <IconButton
            color="secondary"
            onClick={() => handleShowMetadata(params.row)}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.annotation)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleDelete(params.row.annotation)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Bulk deletion functions for selected rows
  const handleBulkDelete = () => {
    if (rowSelectionModel.length === 0) return;
    showConfirmDialog(
      `Are you sure you want to delete ${rowSelectionModel.length} selected annotation${
        rowSelectionModel.length > 1 ? "s" : ""
      }?`,
      confirmBulkDelete
    );
  };

  const confirmBulkDelete = async () => {
    try {
      const urisToDelete = rowSelectionModel.map((selectedId) => {
        const row = annotations.find((a) => a.id === selectedId);
        return row?.annotation;
      });

      const validUris = urisToDelete.filter(Boolean) as string[];

      await Promise.all(
        validUris.map(async (uri) => {
          const query = DeleteAnnotationQuery(uri);
          const response = await DeleteAnnotation(query);
          if (
            !response ||
            (response as AxiosResponse).status !== 204
          ) {
            throw new Error(`Failed to delete annotation: ${uri}`);
          }
        })
      );

      showSnackbar("Selected annotations deleted successfully!", "success");
    } catch (error) {
      console.error(error);
      showSnackbar("Failed to delete some annotations", "error");
    } finally {
      fetchData();
      setRowSelectionModel([]);
    }
  };

  // Deletion functions for "Delete All"
  const handleDeleteAll = () => {
    showConfirmDialog(
      "Are you sure you want to delete ALL annotations?",
      confirmDeleteAll
    );
  };

  const confirmDeleteAll = async () => {
    try {
      await Promise.all(
        annotations.map(async (annotation) => {
          const query = DeleteAnnotationQuery(annotation.annotation);
          const response = await DeleteAnnotation(query);
          if ((response as AxiosResponse).status !== 204) {
            throw new Error(`Failed to delete annotation: ${annotation.annotation}`);
          }
        })
      );
      showSnackbar("All annotations deleted successfully!", "success");
    } catch (error) {
      console.error(error);
      showSnackbar("Failed to delete all annotations", "error");
    } finally {
      fetchData();
      setRowSelectionModel([]);
    }
  };

  const extractValue = (fieldValue: any): string => {
    if (fieldValue && typeof fieldValue === "object" && "value" in fieldValue) {
      return fieldValue.value;
    }
    return fieldValue || "N/A";
  };

  const fields = (metadata: any) => {
    return [
      { label: "Exact Text", value: extractValue(metadata?.exact) },
      {
        label: "Created",
        value: metadata.created
          ? new Date(extractValue(metadata.created)).toLocaleString()
          : "N/A",
      },
      {
        label: "Implicit Author Name",
        value: extractValue(metadata?.implicitAuthorName),
      },
      {
        label: "Explicit Author Name",
        value: extractValue(metadata?.explicitAuthorName),
      },
      { label: "Title Stated", value: extractValue(metadata?.titleStated) },
      { label: "Author Stated", value: extractValue(metadata?.authorStated) },
      {
        label: "Has Quotation Marks",
        value: extractValue(metadata?.hasQuotationMarks),
      },
      {
        label: "Implicit Source Wording",
        value: extractValue(metadata?.implicitSourceWording),
      },
      {
        label: "Explicit Source Wording",
        value: extractValue(metadata?.explicitSourceWording),
      },
      {
        label: "Implicit Literary Source Main Title",
        value: extractValue(metadata?.implicitLiterarySourceMainTitle),
      },
      {
        label: "Explicit Literary Source Main Title",
        value: extractValue(metadata?.explicitLiterarySourceMainTitle),
      },
      {
        label: "Egotext Main Title",
        value: extractValue(metadata?.egotextMainTitle),
      },
      {
        label: "Egotext Wording",
        value: extractValue(metadata?.egotextWording),
      },
      {
        label: "Research Literature Main Title",
        value: extractValue(metadata?.researchLiteratureMainTitle),
      },
      {
        label: "Research Literature Passage Part",
        value: extractValue(metadata?.researchLiteraturePassagePart),
      },
    ];
  };

  return (
    <>
      {/* Toolbar with conditional delete button */}
      {annotations.length > 0 && rowSelectionModel.length > 0 && (
        <Box mb={2}>
          <Button
            variant="contained"
            color="error"
            onClick={
              rowSelectionModel.length === annotations.length
                ? handleDeleteAll
                : handleBulkDelete
            }
          >
            {rowSelectionModel.length === annotations.length
              ? "Delete All Annotations"
              : "Delete Selected"}
          </Button>
        </Box>
      )}

      <Box
        sx={{
          height: 600,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {annotations.length > 0 ? (
          <DataGrid
            rows={annotations}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newSelection) =>
              setRowSelectionModel(newSelection)
            }
            slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mt={4}
          >
            <Link component={RouterLink} to="/annotate">
              <AddCircleOutlineIcon sx={{ color: "gray", fontSize: 32 }} />
            </Link>
            <Typography variant="body1" my={2}>
              No annotations added yet, Add new!
            </Typography>
          </Box>
        )}
      </Box>

      {/* Dialog for editing an annotation */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="edit-dialog-title"
      >
        <DialogContent>
          <RogerFrame
            schema={schema}
            initialQuads={editQuads}
            endpoints={endpoints}
            config={config}
            onSave={onSave}
            mode="edit"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for displaying annotation metadata */}
      <Dialog
        open={metadataDialogOpen}
        onClose={() => setMetadataDialogOpen(false)}
        aria-labelledby="metadata-dialog-title"
        PaperProps={{ sx: { width: 700, maxWidth: "90%" } }}
      >
        <DialogContent dividers>
          {metadataData ? (
            <Box>
              <Typography variant="h4" gutterBottom>
                Annotation Metadata
              </Typography>
              {fields(metadataData).map((field, index) => (
                <Box key={index} my={1}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {field.label}:
                  </Typography>
                  <Typography variant="body1">{field.value}</Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body2">
              No metadata found for this annotation.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMetadataDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {SnackbarComponent}
      {ConfirmDialogComponent}
    </>
  );
};

export default Annotations;
