// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import Annotations from "../components/rosen-shapes/Annotations";
import Persons from "../components/rosen-shapes/Persons";
import SourcePassages from "../components/rosen-shapes/SourcePassages";
import Egotexts from "../components/rosen-shapes/EgotextPassages";
import ResearchLiteraturePassages from "../components/rosen-shapes/ResearchLiteraturePassages";
import Editions from "../components/rosen-shapes/Editions";
import Works from "../components/rosen-shapes/Works";
import Institutions from "../components/rosen-shapes/Institutions";
import ISBN from "../components/rosen-shapes/Isbns";
import Places from "../components/rosen-shapes/Places";
import Publishers from "../components/rosen-shapes/Publishers";
import Concepts from "../components/rosen-shapes/Concepts";
import ConceptSchemes from "../components/rosen-shapes/ConceptSchemes";
import AddResource from "../utils/AddResource";
import { GenerateResourceQuad } from "../utils/helpers";
import { useTranslation } from 'react-i18next';

interface PageProps {
  node: any;
}

const editComponentMap: Record<
  string,
  React.ComponentType<{ refresh: any }>
> = {
  searchinstitution: Institutions,
  searchannotation: Annotations,
  searchperson: Persons,
  searcsource: SourcePassages,
  searchegotext: Egotexts,
  searchresearch: ResearchLiteraturePassages,
  searchinstance: Editions,
  searchwork: Works,
  searchisbn: ISBN,
  searchplace: Places,
  searchpublisher: Publishers,
  searchconcept: Concepts,
  searchconceptscheme: ConceptSchemes,
};

const NodeComponent: React.FC<PageProps> = ({ node }) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const getNewInitialQuads = async () => {
    const initialQuad = await GenerateResourceQuad(node.namespace, node.name);
    return initialQuad;
  };

  const refreshAuthors = () => {
    setRefresh((prev) => !prev);
  };

  const Component = editComponentMap[node.path];
  if (Component) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            {t(node.label)}
          </Typography>
          {node.path !== "searchannotation" && (
            <AddResource
              getNewInitialQuads={getNewInitialQuads}
              onSaveComplete={refreshAuthors}
              buttonLabel={`Add ${node.label}`}
            />
          )}
        </Box>
        <Component refresh={refresh} />
      </Container>
    );
  }
};

export default NodeComponent;
