// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: CC0-1.0

// This file was generated by generatePrefixesFromContext.js:
// DO NOT MODIFY!
// Edit context.json instead.

// prettier-ignore
export default {
  "sh": {
    "IRI": "http://www.w3.org/ns/shacl#IRI",
    "NodeShape": "http://www.w3.org/ns/shacl#NodeShape",
    "PropertyShape": "http://www.w3.org/ns/shacl#PropertyShape",
    "class": "http://www.w3.org/ns/shacl#class",
    "datatype": "http://www.w3.org/ns/shacl#datatype",
    "description": "http://www.w3.org/ns/shacl#description",
    "name": "http://www.w3.org/ns/shacl#name",
    "node": "http://www.w3.org/ns/shacl#node",
    "nodeKind": "http://www.w3.org/ns/shacl#nodeKind",
    "path": "http://www.w3.org/ns/shacl#path",
    "property": "http://www.w3.org/ns/shacl#property",
    "targetClass": "http://www.w3.org/ns/shacl#targetClass"
  },
  "rdf": {
    "type": "http://www.w3.org/1999/02/22-rdf-syntax-ns#type"
  },
  "rdfs": {
    "label": "http://www.w3.org/2000/01/rdf-schema#label"
  },
  "roger": {
    "exportFileNameProperty": "https://www.sub.uni-goettingen.de/roger/schema#exportFileNameProperty",
    "formNode": "https://www.sub.uni-goettingen.de/roger/schema#formNode",
    "prefillUrlTemplate": "https://www.sub.uni-goettingen.de/roger/schema#prefillUrlTemplate",
    "SelectRemoteInstanceEditor": "https://www.sub.uni-goettingen.de/roger/schema#SelectRemoteInstanceEditor"
  },
  "xsd": {
    "anyURI": "http://www.w3.org/2001/XMLSchema#anyURI",
    "boolean": "http://www.w3.org/2001/XMLSchema#boolean",
    "string": "http://www.w3.org/2001/XMLSchema#string"
  }
}
