// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
const Prefixes = `PREFIX sh: <http://www.w3.org/ns/shacl#>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX sorsh: <https://star.sub.uni-goettingen.de/schema/>
PREFIX sor: <https://star.sub.uni-goettingen.de/model/>
PREFIX sorvocE: <https://star.sub.uni-goettingen.de/voc/egotexttype/>
PREFIX sorvocC: <https://star.sub.uni-goettingen.de/voc/contributionrole/>
PREFIX sorvocR: <https://star.sub.uni-goettingen.de/voc/referencetype/>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX roger: <https://www.sub.uni-goettingen.de/roger/schema#>
PREFIX dash: <http://datashapes.org/dash#>
PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
PREFIX bf: <http://id.loc.gov/ontologies/bibframe-2-2-0/>
PREFIX oa: <http://www.w3.org/ns/oa#>
PREFIX foaf: <http://xmlns.com/foaf/0.1/>
PREFIX owl: <http://www.w3.org/2002/07/owl#>
PREFIX intro: <https://w3id.org/lso/intro/beta202304#>
PREFIX dct: <http://purl.org/dc/terms/>
PREFIX locvoc: <http://id.loc.gov/vocabulary/relators/#>
PREFIX sord: <https://star.sub.uni-goettingen.de/data/> 
`;


export default Prefixes;